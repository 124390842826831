import React, { ReactElement } from "react";
import * as app from '../services/AppService'
//@ts-ignore
import  bootstrap from 'bootstrap/dist/js/bootstrap.bundle.min.js' 
import { RowItem } from "../models/row-item";

interface Props extends RowItem { 
    ID:string
    Options?: any[],
    Data?: any[],
    Class?: string,
    LabelClass?: string,
    ItemClass?: string,
    Disabled?: boolean,
    Placeholder?: string,
    Label?: string,
    Icon?: string,
    Value?: string,
    Key?: string,
    Table?: boolean,
    AutoClose?: string,
    SNclicked?:(...args:any) => any
    children?:ReactElement[]
}

function DropdownButton(props:Props) {

    function closeMenu(e:React.MouseEvent<HTMLUListElement>) {
        //@ts-ignore
        if (e && e.target && e.target.classList && e.target.classList.contains("dropdown-item")) return;//اغلاق المنيو الا اذا كان الزر المضغوط لمنوي تاني
        let toggleBtn = document.getElementById(props.ID)
        console.log("toggleBtn", toggleBtn)
        if (toggleBtn) {
            let dropdownEl = new bootstrap.Dropdown(toggleBtn);
            dropdownEl.hide();
        }
    }

    return (
        <div className={"btn-group " + props.Class}>

            <button className={`btn btn-sm dropdown-toggle  ${props.LabelClass}`} data-bs-auto-close={props.AutoClose}
                data-bs-toggle="dropdown" aria-expanded="false" id={props.ID}>
                {app.translate(props.Label)}
                {props.Icon && <span className={`iconssc-${props.Icon}`}></span>}
            </button>

            <ul className={"dropdown-menu" + props.ItemClass} aria-labelledby="dropdownMenuButton" onClick={closeMenu}>
                {props.children ?
                    Array.isArray(props.children) && props.children[0].type == "li" ? props.children:
                    <li>{props.children}</li>
                    :
                    props.Options!.map((item, index) => {
                        return (
                            <li key={index}>
                                <button className="dropdown-item fw-bold" style={{ "fontSize": "12px" }} onClick={() => props.SNclicked!(item[props.Key!], props.Data || [])}>
                                    {item[props.Value!]}
                                </button>
                                <hr className="m-0 dropdown-divider" />
                            </li>

                        );
                    })
                }
            </ul>
        </div>
    )
}

DropdownButton.defaultProps = {
    Options: [],
    Data: [],
    Disabled: false,
    Label: '',
    Class: '',
    LabelClass: 'btn-primary ',
    ItemClass: ' mx-1 p-1 ',
    Value: "Value",
    Key: "Key",
    AutoClose: "auto",
    Table: false,
}
export default DropdownButton;