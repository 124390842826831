import { lazy } from 'react';

export const publicRoutes = [
    { perm: 0, path: '/Signin', element: lazy(() => import("../pages/Public/LogIn")) },
    { path: '', element: lazy(() => import("../pages/Home")) },
    { path: '/', element: lazy(() => import("../pages/Home")) },
    { path: '/OurAgents', element: lazy(() => import("../pages/Public/OurAgents")) },
    { path: '/TrackTransfer', element: lazy(() => import("../pages/Public/RemTrack")) },
    { path: '/UpdateExpiredPass', routeParam: "branchID", element: lazy(() => import("../pages/Public/UpdateExpiredPass")) }
]

export const userRoutes = [
    ...publicRoutes,
    //#region UserDashboard
    { perm: 0, path: '/UserDashboard', element: lazy(() => import("../pages/Dashboard/UserDashboard")) },
    { name: 'MessageSystem', perm: 0, path: '/MessageSystem', element: lazy(() => import("../pages/SharedPages/MessageSystem")) },
    //#endregion
    //#region محاسبة
    //#region ادارة الحسابات 1
    { parentId: 1, perm: 2, name: 'FrmAccountsList', path: '/AccManag/AccountsTree', element: lazy(() => import("../pages/User/Accounting/AccManagement/AccountsTree")) },
    { parentId: 1, perm: 8, name: 'FrmAccountCard', path: '/AccManag/AccountCard', element: lazy(() => import("../pages/User/Accounting/AccManagement/AccountCard")) },
    { parentId: 1, perm: 9, name: 'AccDefault', path: '/AccManag/DefaultAcc', element: lazy(() => import("../pages/User/Accounting/AccManagement/DefaultAcc")) },
    { parentId: 1, perm: 31, name: 'FrozenAcc', path: '/AccManag/FrozenAcc', element: lazy(() => import("../pages/User/Accounting/AccManagement/FrozenAcc")) },
    { parentId: 1, perm: 36, name: 'FrmDgreeImportAccounts', path: '/AccManag/UnsualDegreeImportAcc', element: lazy(() => import("../pages/User/Accounting/AccManagement/UnsualDegreeImportAcc")) },
    { parentId: 1, perm: 29, name: 'FrmCostCenters', path: '/AccManag/CostCenters', element: lazy(() => import("../pages/User/Accounting/AccManagement/CostCenters")) },
    { parentId: 1, perm: 37, name: 'FrmTradingSlides', path: '/AccManag/TradingSlides', element: lazy(() => import("../pages/User/Accounting/AccManagement/TradingSlides")) },
    { parentId: 1, perm: 38, name: 'FrmAccountCeilings', path: '/AccManag/AccountCeilings', element: lazy(() => import("../pages/User/Accounting/AccManagement/AccountCeilings")) },
    //#endregion
    //#region تعريف كوة 2
    { parentId: 2, perm: 10, name: 'AddTeller', path: '/AccManag/AddTeller', element: lazy(() => import("../pages/User/Accounting/AddTeller")) },
    //#endregion
    //#region شرائح الاجور 2
    { parentId: 12, perm: 41, name: 'FrmExchCommPrice', path: '/AccManag/Wages', element: lazy(() => import("../pages/User/Accounting/Wages")) },
    //#endregion
    //#region العمليات اليومية 3
    { parentId: 3, perm: 3, name: 'Receipts', path: '/AccOperations/CashOperations/Receive', element: lazy(() => import("../pages/User/Accounting/Operations/CashOperations")), routeParam: "id", ulrParam: "0", props: { Kind: 3 } },
    { parentId: 3, perm: 4, name: 'Payments', path: '/AccOperations/CashOperations/Payment', element: lazy(() => import("../pages/User/Accounting/Operations/CashOperations")), routeParam: "id", ulrParam: "0", props: { Kind: 4 } },
    { parentId: 3, perm: 14, name: 'PayAccIn', path: '/AccOperations/CashOperations/Deposit', element: lazy(() => import("../pages/User/Accounting/Operations/CashOperations")), routeParam: "id", ulrParam: "0", props: { Kind: 10 } },
    { parentId: 3, perm: 16, name: 'PayAccOut', path: '/AccOperations/CashOperations/Withdraw', element: lazy(() => import("../pages/User/Accounting/Operations/CashOperations")), routeParam: "id", ulrParam: "0", props: { Kind: 11 } },
    { parentId: 3, perm: 18, name: 'OrderPay', path: '/AccOperations/CashOperations/CashOrder', element: lazy(() => import("../pages/User/Accounting/Operations/CashOperations")), routeParam: "id", ulrParam: "0", props: { Kind: 13 } },
    { parentId: 3, perm: 7, name: 'Adjustment', path: '/AccOperations/Adjustment', element: lazy(() => import("../pages/User/Accounting/Operations/Adjustment")), routeParam: "id", ulrParam: "0" },
    { parentId: 3, perm: 17, name: 'FrmCoverBalance', path: '/AccOperations/CoverBalance', element: lazy(() => import("../pages/User/Accounting/Operations/CoverBalance")), routeParam: "id", ulrParam: "0" },
    { parentId: 3, perm: 7, name: 'FrmAccountCredit', path: '/AccOperations/CreditToAccount', element: lazy(() => import("../pages/User/Accounting/Operations/CreditToAccount")), routeParam: "id", ulrParam: "0" },
    { parentId: 3, perm: 7, name: 'FrmAdjustingL4', path: '/AccOperations/AdjustingL4', element: lazy(() => import("../pages/User/Accounting/Operations/AdjustingL4")), routeParam: "id", ulrParam: "0" },
    { parentId: 3, perm: 35, name: 'ExchTransaction', path: '/AccOperations/BoxesTransfer', element: lazy(() => import("../pages/User/Accounting/Operations/BoxesTransfer")), routeParam: "id", ulrParam: "0" },
    { parentId: 3, perm: 35, name: 'ConfirmTeller', path: '/AccOperations/BoxesTransferConfirm', element: lazy(() => import("../pages/User/Accounting/Operations/BoxesTransferConfirm")), routeParam: "id", ulrParam: "0" },
    { parentId: 3, perm: 1, name: 'EntryVoucher', path: '/AccOperations/AccountEntry', element: lazy(() => import("../pages/User/Accounting/Operations/AccountEntry")), routeParam: "id", ulrParam: "0" },
    //#endregion
    //#region عمليات الوكيل 20
    { parentId: 20, perm: 24, name: 'CreditApproval', path: '/AgentOperations/CreditTransfer', element: lazy(() => import("../pages/User/Accounting/AgentOperations/CreditTransfer")), routeParam: "id", ulrParam: "0" },
    { parentId: 20, perm: 26, name: 'FrmTransferUndeProgress', path: '/AgentOperations/TransferUndeProgress', element: lazy(() => import("../pages/User/Accounting/AgentOperations/CreditTransfer")), routeParam: "id", ulrParam: "0" },
    { parentId: 20, perm: 25, name: 'BetweenCurrencies', path: '/AgentOperations/BetweenCurrencies', element: lazy(() => import("../pages/User/Accounting/AgentOperations/BetweenCurrencies")), routeParam: "id", ulrParam: "0" },
    { parentId: 20, perm: 34, name: 'FrmTradingOperation', path: '/AgentOperations/TradingOperation', element: lazy(() => import("../pages/User/Accounting/AgentOperations/Trading")), routeParam: "id", ulrParam: "0" },
    { parentId: 20, perm: 1044, name: 'FrmUnsentCredits', path: '/AgentOperations/UnsentCredits', element: lazy(() => import("../pages/User/Accounting/AgentOperations/UnsentCredits")) },
    //#endregion
    //#region كشوف اليومية 4
    { parentId: 4, perm: 1016, name: 'ConfirmFund', path: '/AccDailyReports/ConfirmAllNone', element: lazy(() => import("../pages/User/Accounting/DailyReports/ConfirmAllNone")), props: { FollowUp: false } },
    { parentId: 4, perm: 1016, name: 'FrmConfirmFollowUp', path: '/AccDailyReports/ConfirmFollowUp', element: lazy(() => import("../pages/User/Accounting/DailyReports/ConfirmAllNone")), props: { FollowUp: true } },
    { parentId: 4, perm: 1028, name: 'FrmLockedAllDone', path: '/AccDailyReports/LockedMovements', element: lazy(() => import("../pages/User/Accounting/DailyReports/LockedMovements")) },
    { parentId: 4, perm: 1029, name: 'FrmAllOperationsTellers', path: '/AccDailyReports/AllOperationsTellers', element: lazy(() => import("../pages/User/Accounting/DailyReports/OperationsOfTellers")) },
    { parentId: 4, perm: 1030, name: 'QueryCreditCard', path: '/AccDailyReports/CreditCardQuery', element: lazy(() => import("../pages/User/Accounting/DailyReports/CreditCardQuery")) },
    { parentId: 4, perm: 1031, name: 'FrmConfirmCoverBalance', path: '/AccDailyReports/ConfirmCoverBalance', element: lazy(() => import("../pages/User/Accounting/DailyReports/ConfirmCoverBalance")) },
    { parentId: 4, perm: 1040, name: 'FrmConfirmAllNoneStatment', path: '/AccDailyReports/ConfirmTellerOperations', element: lazy(() => import("../pages/User/Accounting/DailyReports/ConfirmTellerOperations")) },
    { parentId: 4, perm: 1042, name: 'FrmCancelAllDone', path: '/AccDailyReports/CanceledMovements', element: lazy(() => import("../pages/User/Accounting/DailyReports/CanceledMovements")) },
    { parentId: 4, perm: 1045, name: 'FrmAdjOperatStat', path: '/AccDailyReports/AdjOperatStat', element: lazy(() => import("../pages/User/Accounting/DailyReports/AdjOperatStat")) },
    { parentId: 4, perm: 1053, name: 'FrmUserJournal', path: '/AccDailyReports/UserJournal', element: lazy(() => import("../pages/User/Accounting/DailyReports/UserJournal")) },
    { parentId: 4, perm: 1053, name: 'FrmUserTrading', path: '/AccDailyReports/UserTrading', element: lazy(() => import("../pages/User/Accounting/DailyReports/UserTrading")) },
    { parentId: 4, perm: 1052, name: 'FrmTransactionsState', path: '/AccDailyReports/TransactionsState', element: lazy(() => import("../pages/User/Accounting/DailyReports/TransactionsState")) },
    //#endregion
    //#region الشيكات -5
    { parentId: 5, perm: 13, name: 'FrmCheckbook', path: '/AccChecks/CheckBook', element: lazy(() => import("../pages/User/Accounting/Checks/CheckBook"))},
    { parentId: 5, perm: 20, name: 'FrmCheckCancel', path: '/AccChecks/CheckCancel', element: lazy(() => import("../pages/User/Accounting/Checks/CheckCancel"))},
    { parentId: 5, perm: 1017, name: 'frmCheckEmpety', path: '/AccChecks/CheckInventory', element: lazy(() => import("../pages/User/Accounting/Checks/CheckInventory")) },
    { parentId: 5, perm: 1006, name: 'frmChecksBanking', path: '/AccChecks/ChecksBanking', element: lazy(() => import("../pages/User/Accounting/Checks/ChecksBanking")) },

    //#endregion
    //#region الكشوف المحاسبية 6
    { parentId: 6, perm: 1003, name: 'Journal', path: '/AccQueries/Journal', element: lazy(() => import("../pages/User/Accounting/AccQueries/Journal")) },
    { parentId: 6, perm: 1001, name: 'Ledger', path: '/AccQueries/Ledger', element: lazy(() => import("../pages/SharedPages/Ledger")) },
    { parentId: 6, perm: 1015, name: 'MovingBalancesDaily', path: '/AccQueries/MovingBalancesDaily', element: lazy(() => import("../pages/User/Accounting/AccQueries/MovingBalancesDaily")) },
    { parentId: 6, perm: 1002, name: 'TrailBalance', path: '/AccQueries/TrailBalance', element: lazy(() => import("../pages/User/Accounting/AccQueries/TrialBalance")) },
    { parentId: 6, perm: 1011, name: 'TrailBalanceGeneral', path: '/AccQueries/TrialBalanceGeneral', element: lazy(() => import("../pages/User/Accounting/AccQueries/TrialBalanceGeneral")) },
    { parentId: 6, perm: 1011, name: 'FrmTrailBalanceGroup', path: '/AccQueries/AggregationTrialBalance', element: lazy(() => import("../pages/User/Accounting/AccQueries/AggregationTrialBalance")) },
    { parentId: 6, perm: 1012, name: 'BalancesFund', path: '/AccQueries/BalancesFund', element: lazy(() => import("../pages/User/Accounting/AccQueries/BalancesFund")) },
    { parentId: 6, perm: 1019, name: 'FrmAccountLastConform', path: '/AccQueries/MatchingAcc', element: lazy(() => import("../pages/User/Accounting/AccQueries/MatchingAcc")) },
    { parentId: 6, perm: 1027, name: 'FrmAccountDebtFrozen', path: '/AccQueries/AccountDebtFrozen', element: lazy(() => import("../pages/User/Accounting/AccQueries/AccountDebtFrozen")) },
    { parentId: 6, perm: 1032, name: 'FrmTaxStatment', path: '/AccQueries/TaxStatment', element: lazy(() => import("../pages/User/Accounting/AccQueries/TaxStatment")) },
    { parentId: 6, perm: 1005, name: 'BalancesCategories', path: '/AccQueries/BalancesCategories', element: lazy(() => import("../pages/User/Accounting/AccQueries/BalancesCategories")) },
    { parentId: 6, perm: 1004, name: 'FrmAccountBalance', path: '/AccQueries/AccBalanceView', element: lazy(() => import("../pages/User/Accounting/AccQueries/AccBalanceView")) },
    { parentId: 6, perm: 1038, name: 'FrmReceivablesQuery', path: '/AccQueries/ReceivablesQuery', element: lazy(() => import("../pages/User/Accounting/AccQueries/ReceivablesQuery")) },
    { parentId: 6, perm: 1049, name: 'FrmAccBranchStat', path: '/AccQueries/AccBranchStat', element: lazy(() => import("../pages/User/Accounting/AccQueries/AccBranchStat")) },
    //#endregion
    //#region استعلامات احصائية -8
    { parentId: 8, perm: 1026, name: 'AccStatic', path: '/StaticQueries/AccStatic', element: lazy(() => import("../pages/User/Accounting/StaticQueries/AccStatic")) },
    { parentId: 8, perm: 1021, name: 'CurMostTrade', path: '/StaticQueries/MostTradeCurr', element: lazy(() => import("../pages/User/Accounting/StaticQueries/MostTradeCurr")) },
    { parentId: 8, perm: 1022, name: 'PricesAvg', path: '/StaticQueries/PusrchaseSaleAvgPrices', element: lazy(() => import("../pages/User/Accounting/StaticQueries/PusrchaseSaleAvgPrices")) },
    { parentId: 8, perm: 1041, name: 'FrmUserRating', path: '/StaticQueries/UsersRating', element: lazy(() => import("../pages/User/Accounting/StaticQueries/UsersRating")) },
    { parentId: 8, perm: 1023, name: 'FrmCurrencysPrices', path: '/StaticQueries/CurrencysPrices', element: lazy(() => import("../pages/User/Accounting/StaticQueries/CurrencysPrices")) },
    { parentId: 8, perm: 1054, name: 'FrmGeneralEvaluation', path: '/StaticQueries/GeneralEvaluation', element: lazy(() => import("../pages/User/Accounting/StaticQueries/GeneralEvaluation")) },
    { parentId: 8, perm: 1055, name: 'FrmWithdrawalsAndDepositsOperations', path: '/StaticQueries/WithdrawAndDeposits', element: lazy(() => import("../pages/User/Accounting/StaticQueries/WithdrawAndDeposits")) },
    //#endregion
    //#region استعلامات خاصة 9
    { parentId: 9, perm: 1036, name: 'FrmCostCurr', path: '/PrivateQueries/CurrencyCost', element: lazy(() => import("../pages/User/Accounting/PrivateQueries/CurrencyCost")) },
    { parentId: 9, perm: 1035, name: 'FrmAccPurchSales', path: '/PrivateQueries/PurchasesSalesAcc', element: lazy(() => import("../pages/User/Accounting/PrivateQueries/AccPurchSales")) },
    { parentId: 9, perm: 1039, name: 'FrmBranchPurchSales', path: '/PrivateQueries/BranchPurchSales', element: lazy(() => import("../pages/User/Accounting/PrivateQueries/BranchPurchSales")) },
    { parentId: 9, perm: 1020, name: 'PurchasesSales', path: '/PrivateQueries/PurchasesSales', element: lazy(() => import("../pages/User/Accounting/PrivateQueries/PurchasesSales")) },
    { parentId: 9, perm: 1037, name: 'FrmAccCommStat', path: '/PrivateQueries/AccCommStat', element: lazy(() => import("../pages/User/Accounting/PrivateQueries/AccCommStat")) },
    { parentId: 9, perm: 1046, name: 'FrmAccMoveAnalysis', path: '/PrivateQueries/AccMoveAnalysis', element: lazy(() => import("../pages/User/Accounting/PrivateQueries/AccMoveAnalysis")) },
    { parentId: 9, perm: 1048, name: 'FrmBrCommStat', path: '/PrivateQueries/BrCommStat', element: lazy(() => import("../pages/User/Accounting/PrivateQueries/BrCommStat")) },
    //#endregion
    //#region الحسابات الختامية 10
    { parentId: 10, perm: 1013, name: 'FrmListIncome', path: '/FinalAccountClose/ListIncome', element: lazy(() => import("../pages/User/Accounting/FinalAccClose/ListIncome")) },
    //#endregion
    //#region تحليل مالي 11
    { parentId: 11, perm: 11, name: 'CuttingValuation', path: '/FinancialAnalysis/ForeignExchangeStocks', element: lazy(() => import("../pages/User/Accounting/FinancialAnalysis/ForeignExchangeStocks")) },
    //#endregion
    //#endregion
    //#region الامتثال    FrmSuspiciousTransactions
    { parentId: 90, perm: 3008, name: 'FrmCardIdentity', path: '/Compliance/ClientCard', element: lazy(() => import("../pages/User/Compliance/ClientCard")), routeParam: "id", ulrParam: "0" },
    //#region الاستعلامات
    { parentId: 91, perm: 4003, name: 'GeneralQueryClients', path: '/Compliance/GeneralQueryClients', element: lazy(() => import("../pages/User/Compliance/CompQueries/GeneralQueryClients")) },
    { parentId: 91, perm: 13210, name: 'FrmCustomerListSearch', path: '/Compliance/SpecialQueryClients', element: lazy(() => import("../pages/User/Compliance/CompQueries/SpecialQueryClients")), routeParam: "name", ulrParam: "0" },
    { parentId: 91, perm: 13211, name: 'FrmRecurrentOperations', path: '/Compliance/RepeatedCustomersOperations', element: lazy(() => import("../pages/User/Compliance/CompQueries/RepeatedCustomersOperations")) },
    { parentId: 91, perm: 13212, name: 'FrmCustomerMovSearch', path: '/Compliance/CustomerMovSearch', element: lazy(() => import("../pages/User/Compliance/CompQueries/CustomerMovSearch")) },
    { parentId: 91, perm: 13201, name: 'FrmRemittInOutStat', path: '/Compliance/RemittInOutQuiry', element: lazy(() => import("../pages/User/Compliance/CompQueries/RemittInOutQuiry")) },
    { parentId: 91, perm: 13214, name: 'FrmGeogAreaRiskStat', path: '/Compliance/GeogAreaRiskQuiry', element: lazy(() => import("../pages/User/Compliance/CompQueries/GeogAreaRiskQuiry")) },
    { parentId: 91, perm: 13215, name: 'FrmArchivedCardsQuery', path: '/Compliance/ArchivedCardsQuery', element: lazy(() => import("../pages/User/Compliance/CompQueries/ArchivedCardsQuery")) },
    { parentId: 91, perm: 13217, name: 'FrmStatisticCustomers', path: '/Compliance/StatisticCustomers', element: lazy(() => import("../pages/User/Compliance/CompQueries/StatisticCustomers")) },
    //#endregion
    { parentId: 92, perm: 13002, name: 'Blacklists', path: '/Compliance/BlackListUpdate', element: lazy(() => import("../pages/User/Compliance/BlackListUpdate")) },
    { perm: 13002, name: 'Edit Black Lists', path: '/Compliance/EditBlackList', element: lazy(() => import("../pages/User/Compliance/EditBlackLists")) },
    { parentId: 93, perm: 13010, name: 'FrmCardSetting', path: '/Compliance/ClientCardSetting', element: lazy(() => import("../pages/User/Compliance/ClientCardSetting")) },
    { parentId: 94, perm: 3036, name: 'FrmCustCardModifiReq', path: '/Compliance/CustCardModifiReq', element: lazy(() => import("../pages/User/Compliance/CustCardModifiReq")) },
    { parentId: 95, perm: 13213, name: 'FrmCustomerDangerSearch', path: '/Compliance/MoveTransfComplDepart', element: lazy(() => import("../pages/User/Compliance/MoveTransfComplDepart")) },
    //#endregion
    //#region ادارة الوكلاء
    { parentId: 50, perm: 0, perms: "7026,7027,7028,7020,7018,7035", name: 'Definitions', path: '/AgentsMng/Definitions', element: lazy(() => import("../pages/User/AgentsManagement/Definitions")) },
    { parentId: 51, perm: 0, perms: "7026,7027,7028,7020,7018,7035", permKind: "Others", name: 'Confirm', path: '/AgentsMng/Confirm', element: lazy(() => import("../pages/User/AgentsManagement/Confirm")), props: { AgentKind: 0 } },
    { parentId: 52, perm: 0, perms: "7026", name: 'Queries', path: '/AgentsMng/Queries', element: lazy(() => import("../pages/User/AgentsManagement/Queries")) },
    { parentId: 53, perm: 7233, name: 'QueriesStatic', path: '/AgentsMng/StaticQueries', element: lazy(() => import("../pages/User/AgentsManagement/StaticQueries")) },
    { parentId: 54, perm: 7031, name: 'FrmRemitt_PayComm', path: '/AgentsMng/PayComm', element: lazy(() => import("../pages/User/AgentsManagement/PayComm")) },
    { parentId: 55, perm: 7040, name: 'FrmControlPanelAgent', path: '/AgentsMng/AgentControlPanel', element: lazy(() => import("../pages/User/AgentsManagement/AgentControlPanel")), props: { AgentKind: 0 } },
    //#endregion
    //#region ادارة العملاء
    { parentId: 100, perm: 7044, name: 'Definitions', path: '/ClientMng/Definitions', element: lazy(() => import("../pages/User/ClientManagement/Definitions")) },
    { parentId: 101, perm: 7044, permKind: "Others", name: 'Confirm', path: '/ClientMng/Confirm', element: lazy(() => import("../pages/User/AgentsManagement/Confirm")), props: { AgentKind: 4 } },
    { parentId: 103, perm: 7040, name: 'FrmControlPanelClient', path: '/ClientMng/ClientControlPanel', element: lazy(() => import("../pages/User/AgentsManagement/AgentControlPanel")), props: { AgentKind: 4 } },
    //#endregion
    //الحوالات
    //#region
    //العمليات
    //#region 
    { parentId: 31, perm: 7001, name: 'FrmRemittRec', path: '/RemOperations/RemittRec', element: lazy(() => import("../pages/User/Remittance/Operations/RemittRec")), routeParam: "id", ulrParam: "0", props: { Remitt_Kind: 51 } },
    { parentId: 31, perm: 7003, name: 'FrmRemittIn', path: '/RemOperations/RemittIn', element: lazy(() => import("../pages/User/Remittance/Operations/RemittIn")), routeParam: "id", ulrParam: "0"   , props: { Remitt_Kind: 52 } },
    { parentId: 31, perm: 7002, name: 'FrmRemittPay', path: '/RemOperations/RemittPay', element: lazy(() => import("../pages/User/Remittance/Operations/RemittPay")), routeParam: "id", ulrParam: "0", props: { Remitt_Kind: 56 } },
    { parentId: 31, perm: 7005, name: 'FrmRemittOut', path: '/RemOperations/RemittOut', element: lazy(() => import("../pages/User/Remittance/Operations/RemittPay")), routeParam: "id", ulrParam: "0", props: { Remitt_Kind: 55 } },
    { parentId: 31, perm: 7004, name: 'FrmRemittByAcc', path: '/RemOperations/RemittByAcc', element: lazy(() => import("../pages/User/Remittance/Operations/RemittPay")), routeParam: "id", ulrParam: "0", props: { Remitt_Kind: 57 } },
    { parentId: 31, perm: 7010, name: 'FrmRemittInInstant', path: '/RemOperations/InInstant', element: lazy(() => import("../pages/User/Remittance/Operations/RemInstant")), routeParam: "id", ulrParam: "0", props: { Remitt_Kind: 59 } },
    { parentId: 31, perm: 7017, name: 'FrmRemittRecInstant', path: '/RemOperations/RecInstant', element: lazy(() => import("../pages/User/Remittance/Operations/RemInstant")), routeParam: "id", ulrParam: "0", props: { Remitt_Kind: 58 } },
    { parentId: 31, perm: 7022, name: 'FrmRemittInOutInstant', path: '/RemOperations/InOutInstant', element: lazy(() => import("../pages/User/Remittance/Operations/InOutInstant")), routeParam: "id", ulrParam: "0" },
    //#endregion
    //تمويل المستوردات
    //#region
    { parentId: 33, perm: 7030, name: 'FrmImportLicense', path: '/ImportFinance/ImportLicenseOrder', element: lazy(() => import("../pages/User/Remittance/ImportFinance/ImportLicenseOrder")), routeParam: "id", ulrParam: "0" },
    { parentId: 33, perm: 7034, name: 'FrmSellingFinancing', path: '/ImportFinance/SellingFinancing', element: lazy(() => import("../pages/User/Remittance/ImportFinance/SellingFinancing")), routeParam: "id", ulrParam: "0" },
    { parentId: 33, perm: 7042, name: 'FrmPaymentFinancing', path: '/ImportFinance/FinancingPayment', element: lazy(() => import("../pages/User/Remittance/ImportFinance/FinancingPayment")) },

    { parentId: 33, perm: 7237, name: 'FrmGeneralFinancingStat', path: '/ImportFinance/GeneralFinancingStat', element: lazy(() => import("../pages/User/Remittance/ImportFinance/Queries/GeneralFinancingStat")) },
    { parentId: 33, perm: 7229, name: 'FrmFinancingStat', path: '/ImportFinance/FinancingStat', element: lazy(() => import("../pages/User/Remittance/ImportFinance/Queries/FinancingStat")) },
    { parentId: 33, perm: 7239, name: 'FrmImportInvoicesStat', path: '/ImportFinance/ImportInvoicesStat', element: lazy(() => import("../pages/User/Remittance/ImportFinance/Queries/ImportInvoicesStat")) },
    { parentId: 33, perm: 7230, name: 'FrmDueOrdersStat', path: '/ImportFinance/DueOrdersStat', element: lazy(() => import("../pages/User/Remittance/ImportFinance/Queries/DueOrdersStat")) },
    { parentId: 33, perm: 7238, name: 'FrmPaymentFinancingStat', path: '/ImportFinance/PaymentFinancingStat', element: lazy(() => import("../pages/User/Remittance/ImportFinance/Queries/PaymentFinancingStat")) },
    //{ parentId: 33, perm: 7239, name: 'FrmInvoiceSerch', path: '/ImportFinance/FinancingPayment', element: lazy(() => import("../pages/User/Remittance/ImportFinance/FinancingPayment")) },

    //#endregion
    { parentId: 35, perm: 7019, name: 'FrmAddRemttOrderUpdate', path: '/RemQueries/RemittStatus', element: lazy(() => import("../pages/User/Remittance/RemQueries/RemittStatus")) },
    { parentId: 36, perm: 7038, name: 'FrmRemittStatusGeneral', path: '/RemQueries/RemittStatusGeneral', element: lazy(() => import("../pages/User/Remittance/RemQueries/RemittStatusGeneral")) },
    //متابعة الحوالات
    //#region 
    { parentId: 37, perm: 7008, name: 'ImportFax', path: '/RemittFollowUp/ImportFax', element: lazy(() => import("../pages/User/Remittance/RemittancesFollowUp/ImportFax")) },
    { parentId: 37, perm: 7201, name: 'ApproveRemt', path: '/RemittFollowUp/ApproveRemt', element: lazy(() => import("../pages/User/Remittance/RemittancesFollowUp/ApproveRemt")) },
    { parentId: 37, perm: 7204, name: 'ExportRmts', path: '/RemittFollowUp/ExportRmts', element: lazy(() => import("../pages/User/Remittance/RemittancesFollowUp/ExportRmts")) },
    { parentId: 37, perm: 7007, name: 'SendFax', path: '/RemittFollowUp/SendFax', element: lazy(() => import("../pages/User/Remittance/RemittancesFollowUp/SendFax")) },
    { parentId: 37, perm: 7210, name: 'FrmRemittNotifyOut', path: '/RemittFollowUp/FollowingRemtOut', element: lazy(() => import("../pages/User/Remittance/RemittancesFollowUp/FollowingRemtOut")) },
    { parentId: 37, perm: 7227, name: 'FrmRemittExportApi', path: '/RemittFollowUp/RemittExportApi', element: lazy(() => import("../pages/User/Remittance/RemittancesFollowUp/RemittExportApi")) },
    { parentId: 37, perm: 7231, name: 'FrmRemittNotConfirm', path: '/RemittFollowUp/UnConfirmedRemitt', element: lazy(() => import("../pages/User/Remittance/RemittancesFollowUp/UnConfirmedRemitt")) },
    { parentId: 37, perm: 7232, name: 'FrmImportedFaxStat', path: '/RemittFollowUp/ImportedFaxStat', element: lazy(() => import("../pages/User/Remittance/RemittancesFollowUp/ImportedFaxStat")) },
    { parentId: 37, perm: 7236, name: 'FrmRemittCancelFromFax', path: '/RemittFollowUp/RemittFaxMng', element: lazy(() => import("../pages/User/Remittance/RemittancesFollowUp/RemittFaxMng")) },
    //#endregion
    //استعلامات عامة
    //#region 
    { parentId: 38, perm: 7200, name: 'RemtNotDeliverIn', path: '/RemQueries/RemtNotDeliverIn', element: lazy(() => import("../pages/User/Remittance/RemQueries/NotDeliveredTransfers")), props: { IsExternl: false } },
    { parentId: 38, perm: 7202, name: 'RemtNotDeliverOut', path: '/RemQueries/RemtNotDeliverOut', element: lazy(() => import("../pages/User/Remittance/RemQueries/NotDeliveredTransfers")), props: { IsExternl: true } },
    { parentId: 38, perm: 7203, name: 'FrmRemitt_NoneDelivery_VIP', path: '/RemQueries/RemtNotDeliverVip', element: lazy(() => import("../pages/User/Remittance/RemQueries/NotDeliveredTransfers")), props: { IsVip: true } },
    { parentId: 38, perm: 7205, name: 'FrmRemitt_All', path: '/RemQueries/GeneralRemittQuiry', element: lazy(() => import("../pages/User/Remittance/RemQueries/GeneralRemittQuery")), props: { IsExternl: false } },
    { parentId: 38, perm: 7206, name: 'FrmRemitt_Deliv', path: '/RemQueries/DeliveredRemittQuery', element: lazy(() => import("../pages/User/Remittance/RemQueries/DeliveredRemittQuery")) },
    { parentId: 38, perm: 7224, name: 'FrmRemittOutward', path: '/RemQueries/RemittOutward', element: lazy(() => import("../pages/User/Remittance/RemQueries/RemittOutward")) },
    { parentId: 38, perm: 7241, name: 'FrmRemittIncomStat', path: '/RemQueries/RemittIncoming', element: lazy(() => import("../pages/User/Remittance/RemQueries/RemittIncoming")) },
    { parentId: 38, perm: 7226, name: 'FrmWithdrawalSegmentsStat', path: '/RemQueries/WithdrawalSegmentsStat', element: lazy(() => import("../pages/User/Remittance/RemQueries/WithdrawalSegmentsStat")) },
    { parentId: 38, perm: 7015, name: 'QueryRemtOrder', path: '/RemQueries/QueryRemtOrder', element: lazy(() => import("../pages/User/Remittance/RemQueries/RemittModfyRequQuery")), props: { IsAppOrder: false } },
    { parentId: 38, perm: 7015, name: 'QueryRemtInstant', path: '/RemQueries/RemtInstantQuery', element: lazy(() => import("../pages/User/Remittance/RemQueries/RemtInstantQuery")) },
    { parentId: 38, perm: 7207, name: 'FrmRem_InOut_Serch', path: '/RemQueries/InOutAccountRemitsQuery', element: lazy(() => import("../pages/User/Remittance/RemQueries/InOutAccountRemitsQuery")) },
    { parentId: 38, perm: 7211, name: 'FrmRemitt_NonDelivBalanc', path: '/RemQueries/NotDeliverBalances', element: lazy(() => import("../pages/User/Remittance/RemQueries/NotDeliverBalances")) },
    { parentId: 38, perm: 7213, name: 'FrmRmtBanks', path: '/RemQueries/BankRemitQuery', element: lazy(() => import("../pages/User/Remittance/RemQueries/BankRemitQuery")) },
    //#endregion
    //استعلامات احصائية
    //#region 
    { parentId: 39, perm: 7215, name: 'QueryCommCountries', path: '/RemStaticQueries/QueryCommCountries', element: lazy(() => import("../pages/User/Remittance/StaticQueries/QueryCommCountries")) },
    { parentId: 39, perm: 7216, name: 'QueryCommAccs', path: '/RemStaticQueries/QueryCommAccs', element: lazy(() => import("../pages/User/Remittance/StaticQueries/QueryCommAccs")) },
    { parentId: 39, perm: 7212, name: 'FrmComm_All', path: '/RemStaticQueries/CommByCountry', element: lazy(() => import("../pages/User/Remittance/StaticQueries/CommByCountry")) },
    { parentId: 39, perm: 7209, name: 'AccTrading', path: '/RemStaticQueries/AccTrading', element: lazy(() => import("../pages/User/Remittance/StaticQueries/ProfitAcc")) },
    { parentId: 39, perm: 7217, name: 'QueryRemtPrivate', path: '/RemStaticQueries/QueryRemtPrivate', element: lazy(() => import("../pages/User/Remittance/StaticQueries/QueryRemtPrivate")) },
    { parentId: 39, perm: 7240, name: 'FrmRemittNoneDeliveGeneral', path: '/RemStaticQueries/NotDeliveredRemittsGeneral', element: lazy(() => import("../pages/User/Remittance/StaticQueries/NotDeliveredRemittsGeneral")) },
    //#endregion
    //نوافذ مساعدة
    //#region
    { parentId: 40, perm: 7021, name: 'FrmImportModels', path: '/RemHelpWin/ImportModels', element: lazy(() => import("../pages/User/Remittance/HelpWindows/ImportModels")) },
    { parentId: 40, perm: 7037, name: 'FrmRemittCustCeilings', path: '/RemHelpWin/FrmRemittCustCeilings', element: lazy(() => import("../pages/User/Remittance/HelpWindows/FrmRemittCustCeilings")) },

    //endregion
    //استعلامات الحوالات الداخلية
    //#region 
    { parentId: 41, perm: 7218, name: 'FrmBranchesMoveQuery', path: '/InternalRemitsQuery/BranchesMoves', element: lazy(() => import("../pages/User/Remittance/InternalRemitsQuery/BranchesMoves")) },
    { parentId: 41, perm: 7219, name: 'FrmLoadBalForBr', path: '/InternalRemitsQuery/BranchesBalanceSupplyQuery', element: lazy(() => import("../pages/User/Remittance/InternalRemitsQuery/BranchesBalanceSupplyQuery")) },
    { parentId: 41, perm: 7220, name: 'FrmTellersMoveStat', path: '/InternalRemitsQuery/TellersMoves', element: lazy(() => import("../pages/User/Remittance/InternalRemitsQuery/TellersMoves")) },
    { parentId: 41, perm: 7228, name: 'FrmBranchDuesStat', path: '/InternalRemitsQuery/BranchDuesCalc', element: lazy(() => import("../pages/User/Remittance/InternalRemitsQuery/BranchDuesCalc")), props: { IsQuery: false } },
    { parentId: 41, perm: 7220, name: 'FrmBranchDuesQuery', path: '/InternalRemitsQuery/BranchDuesQuery', element: lazy(() => import("../pages/User/Remittance/InternalRemitsQuery/BranchDuesCalc")), props: { IsQuery: true } },
    //#endregion
    //#region تطبيق الزبائن
    { parentId: 42, perm: 7753, name: 'FrmRemittRequests', path: '/CustomerAppMng/RemittRequestsStatment', element: lazy(() => import("../pages/User/Remittance/CustomerAppMng/RemittRequestsStatment")) },
    { parentId: 42, perm: 7706, name: 'FrmExchRequests', path: '/CustomerAppMng/ExchRequests', element: lazy(() => import("../pages/User/Remittance/CustomerAppMng/ExchRequests")) },
    { parentId: 42, perm: 7704, name: 'FrmConfirmRemittRequests', path: '/CustomerAppMng/ConfirmRemittRequests', element: lazy(() => import("../pages/User/Remittance/CustomerAppMng/ConfirmRemittRequests")) },
    { parentId: 42, perm: 7705, name: 'FrmConfirmAuctionRequests', path: '/CustomerAppMng/ConfirmExchRequests', element: lazy(() => import("../pages/User/Remittance/CustomerAppMng/ConfirmExchRequests")) },
    { parentId: 42, perm: 7703, name: 'FrmRemittDir', path: '/CustomerAppMng/RemittDir', element: lazy(() => import("../pages/User/Remittance/CustomerAppMng/RemittDir")) },
    { parentId: 42, perm: 7702, name: 'FrmCustManagApp', path: '/CustomerAppMng/ManagAppCust', element: lazy(() => import("../pages/User/Remittance/CustomerAppMng/ManagAppCust")), routeParam: "Identity_No/:Number/:BirthDate", ulrParam: "0/0/0" },
    { parentId: 42, perm: 3030, name: 'FrmManageAppOffers', path: '/CustomerAppMng/ManageAppOffers', element: lazy(() => import("../pages/User/Remittance/CustomerAppMng/ManageAppOffers")) },
    { parentId: 42, perm: 7701, name: 'FrmAppOptions', path: '/CustomerAppMng/AppOptions', element: lazy(() => import("../pages/User/Remittance/CustomerAppMng/AppOptions")) },
    { parentId: 42, perm: 7751, name: 'FrmAppCustStat', path: '/CustomerAppMng/AppCustStat', element: lazy(() => import("../pages/User/Remittance/CustomerAppMng/AppCustStat")) },
    { parentId: 42, perm: 7754, name: 'QueryRemtOrder', path: '/CustomerAppMng/QueryRemtOrder', element: lazy(() => import("../pages/User/Remittance/RemQueries/RemittModfyRequQuery")), props: { IsAppOrder: true } },
    //#endregion
    //#endregion
    //الحوالات السريعة
    //#region 
    { parentId: 70, perm: 0, perms: "7803,7881,7878", name: 'RemRecApi', path: '/FastTran/RemRec', element: lazy(() => import("../pages/User/FastTransfers/FastRemRec")) },
    { parentId: 70, perm: 0, perms: "7803", name: 'FromAcc', path: '/FastTran/RemRecFromAcc', element: lazy(() => import("../pages/User/FastTransfers/BestRate/RemRecByAcc")) },
    { parentId: 71, perm: 0, perms: "7837,7882,7879", name: 'RemPayApi', path: '/FastTran/RemPay', element: lazy(() => import("../pages/User/FastTransfers/FastRemPay")) },
    //{ parentId: 72, perm: 0, perms: "7803", name: 'StatusRmt', path: '/FastTran/RemStatus', element: lazy(() => import("../pages/User/FastTransfers/RemStatus")), routeParam: "id", ulrParam: "0" },
    { parentId: 73, perm: 0, perms: "7803,7878,7879,7838,7881,7882,7847,7853,7872", name: 'TransactionSearchApi', path: '/FastTran/TransactionSearch', element: lazy(() => import("../pages/User/FastTransfers/FastTransactionSearch")) },
    { parentId: 74, perm: 0, perms: "7803,7837,7838,7874,7876,7878,7879,7882,7881", permKind: "Others", name: 'ManagementUsers', path: '/FastTran/UsersManagement', element: lazy(() => import("../pages/User/FastTransfers/FastUsersManagement")) },
    { parentId: 75, perm: 0, perms: "7803,7837,7838,7874,7876,7878,7879,7882,7881", permKind: "Others", name: 'ApiErrorsQuery', path: '/FastTran/ErrorsQuery', element: lazy(() => import("../pages/User/FastTransfers/FastErrorsQuery")) },
    //#endregion
    //الصرافة
    //#region 
    // شرائح الاجور
    { parentId: 60, perm: 6011, name: 'SlicedWages', path: '/Exch/Wages', element: lazy(() => import("../pages/User/Exchange/Wages")) },

    { parentId: 61, perm: 6002, name: 'FrmInvoiceBuy', path: '/Exch/ExchBuy', element: lazy(() => import("../pages/User/Exchange/ExchOperation")), routeParam: "id", ulrParam: "0", props: { Invoice_Kind: 32 } },
    { parentId: 61, perm: 6003, name: 'FrmInvoiceSale', path: '/Exch/ExchSale', element: lazy(() => import("../pages/User/Exchange/ExchOperation")), routeParam: "id", ulrParam: "0", props: { Invoice_Kind: 33 } },
    { parentId: 61, perm: 6026, name: 'FrmAuctionSale', path: '/Exch/AuctionSale', element: lazy(() => import("../pages/User/Exchange/ExchOperation")), routeParam: "id", ulrParam: "0", props: { Invoice_Kind: 65 } },
    { parentId: 61, perm: 6027, name: 'FrmAuctionPay', path: '/AccOperations/CashOperations/AuctionPay', element: lazy(() => import("../pages/User/Accounting/Operations/CashOperations")), routeParam: "id", ulrParam: "0", props: { Kind: 66 } },
    { parentId: 61, perm: 6028, name: 'FrmAuctionDeliver', path: '/Exch/AuctionDeliver', element: lazy(() => import("../pages/User/Exchange/ExchOperation")), routeParam: "id", ulrParam: "0", props: { Invoice_Kind: 67 } },
    { parentId: 61, perm: 6012, name: 'FrmInvoiceCurrExch', path: '/Exch/InvoiceCurrExch', element: lazy(() => import("../pages/User/Exchange/ExchOperation")), routeParam: "id", ulrParam: "0", props: { Invoice_Kind: 34  } },
    { parentId: 61, perm: 6031, name: 'FrmExternalAuctionInvoice', path: '/Exch/AuctionInvoiceApi', element: lazy(() => import("../pages/User/Exchange/AuctionInvoiceApi")) },
    { parentId: 61, perm: 6031, name: 'FrmExternalAuctionInvoiceRead', path: '/Exch/ExternalAuctionInvoice', element: lazy(() => import("../pages/User/Exchange/ExchOperation")), routeParam: "id", ulrParam: "0", props: { Invoice_Kind: 68 } },

    { parentId: 62, perm: 6008, name: 'FrmGoldMaterial', path: '/Exch/GoldMaterial', element: lazy(() => import("../pages/User/Exchange/GoldMaterial")) },
    { parentId: 63, perm: 6201, name: 'FrmInvoiceSerch', path: '/Exch/InvoicesSearch', element: lazy(() => import("../pages/User/Exchange/InvoicesSearch")) },
    //{ parentId: 64, perm: 6201, name: 'FrmInvoiceSerch', path: '/Exch/InvoicesSearch', element: lazy(() => import("../pages/User/Exchange/InvoicesSearch")) },
    { parentId: 65, perm: 6206, name: 'FrmCommState', path: '/Exch/CommQuiry', element: lazy(() => import("../pages/User/Exchange/CommQuiry")) },
    { parentId: 67, perm: 6212, name: 'FrmExchPurchSales', path: '/Exch/ExchPurchSales', element: lazy(() => import("../pages/User/Exchange/ExchPurchSales")) },
    { parentId: 66, perm: 6207, name: 'FrmExchOperatTeller', path: '/Exch/ExchOperatTeller', element: lazy(() => import("../pages/User/Exchange/ExchOperatTeller")) },
    //#region المزادات
    { parentId: 68, perm: 6019, name: 'FrmAuctionsList', path: '/Exch/AuctionList', element: lazy(() => import("../pages/User/Exchange/Auctions/AuctionList")) },
    { parentId: 68, perm: 6021, name: 'FrmAuctionImport', path: '/Exch/AuctionImport', element: lazy(() => import("../pages/User/Exchange/Auctions/AuctionImport")) },
    { parentId: 68, perm: 6029, name: 'FrmAuctionInvoiceStatements', path: '/Exch/AuctionInvoiceStatus', element: lazy(() => import("../pages/User/Exchange/Auctions/AuctionInvoiceStatus")) },
    { parentId: 68, perm: 6210, name: 'FrmAuctionMoves', path: '/Exch/AuctionMoves', element: lazy(() => import("../pages/User/Exchange/Auctions/AuctionMoves")) },
    { parentId: 68, perm: 6211, name: 'FrmAuctProfitStat', path: '/Exch/AuctProfitStat', element: lazy(() => import("../pages/User/Exchange/Auctions/AuctProfitStat")) },
    { parentId: 68, perm: 6213, name: 'FrmInvoiceSaleUndeliveredStat', path: '/Exch/InvoiceSaleUndeliveredStat', element: lazy(() => import("../pages/User/Exchange/Auctions/InvoiceSaleUndeliveredStat")) },
    { parentId: 68, perm: 6214, name: 'FrmAuctionGeneralStat', path: '/Exch/InvoiceSaleDelivered', element: lazy(() => import("../pages/User/Exchange/Auctions/InvoiceSaleDelivered")) },
    { parentId: 68, perm: 6216, name: 'FrmGeneralAuctInvStat', path: '/Exch/GeneralAuctInvStat', element: lazy(() => import("../pages/User/Exchange/Auctions/GeneralAuctInvStat")) },
    { parentId: 68, perm: 6215, name: 'FrmAuctGeneralStat', path: '/Exch/AuctGeneralStat', element: lazy(() => import("../pages/User/Exchange/Auctions/AuctGeneralStat")) },
    { parentId: 68, perm: 6030, name: 'FrmAuctionOrders', path: '/Exch/AuctionOrders', element: lazy(() => import("../pages/User/Exchange/Auctions/AuctionOrders")) },
    //#endregion
    //#endregion
    //الشحن
    //#region 
    { parentId: 112, perm: 8502, name: 'FrmTrInvoiceIN', path: '/Ship/InvoiceIn', element: lazy(() => import("../pages/User/Shipping/InvoiceIn")) },
    { parentId: 113, perm: 8505, name: 'FrmTrManafestOut', path: '/Ship/ManafestOut', element: lazy(() => import("../pages/User/Shipping/ManafestOut")) },
    { parentId: 113, perm: 8708, name: 'FrmTrManafestInSerch', path: '/Ship/ManafestInSearch', element: lazy(() => import("../pages/User/Shipping/ManafestInSearch")) },
    { perm: 8708, name: 'FrmTrManafestIn', path: '/Ship/ManafestIn', element: lazy(() => import("../pages/User/Shipping/ManafestIn")), routeParam: "outID", ulrParam: "0" },
    { parentId: 114, perm: 8707, name: 'FrmTrInvoiceStore', path: '/Ship/InvoiceDeliverSearch', element: lazy(() => import("../pages/User/Shipping/InvoiceDeliverSearch")) },
    { perm: 8501, name: 'FrmTrInvoiceDeliver', path: '/Ship/InvoiceDeliver', element: lazy(() => import("../pages/User/Shipping/InvoiceDeliver")), routeParam: "ids/:transID", ulrParam: "0" },
    //#endregion
    //البطاقات
    //#region 
    { parentId: 125, perm: 11001, name: 'FrmCardGroup', path: '/Accounting-Cards/CardGroups', element: lazy(() => import("../pages/User/Accounting/Cards/CardGroups")) },
    { parentId: 123, perm: 11002, name: 'FrmCardBuy', path: '/Accounting-Cards/CardBuy', element: lazy(() => import("../pages/User/Accounting/Cards/CardMove")), routeParam: "id", ulrParam: "0", props: { KindMove: 14 } },
    { parentId: 123, perm: 11003, name: 'FrmCardSale', path: '/Accounting-Cards/CardSell', element: lazy(() => import("../pages/User/Accounting/Cards/CardMove")), routeParam: "id", ulrParam: "0", props: { KindMove: 15 } },
    { parentId: 123, perm: 11004, name: 'FrmCardCharge', path: '/Accounting-Cards/RechargeCards', element: lazy(() => import("../pages/User/Accounting/Cards/CardMove")), routeParam: "id", ulrParam: "0", props: { KindMove: 16 } },
    { parentId: 123, perm: 11005, name: 'FrmCardWithdraw', path: '/Accounting-Cards/CardWithdraw', element: lazy(() => import("../pages/User/Accounting/Cards/CardMove")), routeParam: "id", ulrParam: "0", props: { KindMove: 20 } },
    //استعلامات البطاقات
    { parentId: 124, perm: 11201, name: 'FrmInventUnsoldCards', path: '/Accounting-Cards/UnsoldCards', element: lazy(() => import("../pages/User/Accounting/Cards/UnsoldCards")) },
    { parentId: 124, perm: 11202, name: 'FrmPurchSalesCards', path: '/Accounting-Cards/PurchSalesCards', element: lazy(() => import("../pages/User/Accounting/Cards/PurchSalesCards")) },
    { parentId: 124, perm: 11203, name: 'FrmWithdrawChargeCards', path: '/Accounting-Cards/WithdrawChargeCards', element: lazy(() => import("../pages/User/Accounting/Cards/WithdrawChargeCards")) },
    //#endregion
    //#region ادوات
    //#region ادارة العملات 82
    { parentId: 82, perm: 3001, name: 'ListCurrency', path: '/CurrMng/CurrManagement', element: lazy(() => import("../pages/User/Tools/CurrencyManagement/ListCurrency")) },
    { parentId: 82, perm: 3004, name: 'BulletinBranch', path: '/CurrMng/BulletinBranch', element: lazy(() => import("../pages/User/Tools/CurrencyManagement/BulletinBranch")) },
    { parentId: 82, perm: 3005, name: 'BulletinAcc', path: '/CurrMng/BulletinAcc', element: lazy(() => import("../pages/User/Tools/CurrencyManagement/BulletinAcc")) },
    { parentId: 82, perm: 3006, name: 'FrmUsersPriceLists', path: '/CurrMng/UsersPriceLists', element: lazy(() => import("../pages/User/Tools/CurrencyManagement/UsersPriceLists")) },//
    { parentId: 82, perm: 4002, name: 'FrmArchBullCur', path: '/CurrMng/ArchivedCurrencyBulletin', element: lazy(() => import("../pages/User/Tools/CurrencyManagement/ArchivedCurrencyBulletin")) },
    { parentId: 82, perm: 3037, name: 'FrmAccPriceListSp', path: '/CurrMng/AccPriceListSp', element: lazy(() => import("../pages/User/Tools/CurrencyManagement/AccPriceListSp")) },
    { parentId: 82, perm: 3009, name: 'FrmCurrencyPriceSettings', path: '/CurrMng/LivePricesManagement', element: lazy(() => import("../pages/User/Tools/CurrencyManagement/LivePricesManagement")) },
    //#endregion
    //#region ادارة الفروع 80 
    { parentId: 80, perm: 3002, name: 'Branches', path: '/BranchManegment/Branches', element: lazy(() => import("../pages/User/Tools/BranchesManagement/Branches")) },
    { parentId: 80, perm: 3012, name: 'Countries', path: '/BranchManegment/Countries', element: lazy(() => import("../pages/User/Tools/BranchesManagement/Countries")) },
    { parentId: 80, perm: 3011, name: 'Cities', path: '/BranchManegment/Cities', element: lazy(() => import("../pages/User/Tools/BranchesManagement/Cities")) },
    { parentId: 80, perm: 3013, name: 'Nationalities', path: '/BranchManegment/Nationalities', element: lazy(() => import("../pages/User/Tools/BranchesManagement/Nationalities")) },
    { parentId: 80, perm: 3049, name: 'frmArea', path: '/BranchManegment/Areas', element: lazy(() => import("../pages/User/Tools/BranchesManagement/Areas")) },
    { parentId: 80, perm: 3051, name: 'FrmDistricts', path: '/BranchManegment/Districts', element: lazy(() => import("../pages/User/Tools/BranchesManagement/District")) },
    { parentId: 80, perm: 3052, name: 'FrmStreets', path: '/BranchManegment/Streets', element: lazy(() => import("../pages/User/Tools/BranchesManagement/Streets")) },
    //#endregion
    //#region اعادة ضبط كلمة المرور 81
    { parentId: 81, perm: 0, name: 'FrmUserPassword', path: '/ChangeUserPassword', element: lazy(() => import("../pages/User/Tools/ChangeUserPassword")) },
    //#endregion
    //#region النوافذ المساعدة 83
    { parentId: 83, perm: 0, perms: "3014,3007,3019,3025,3035,3038", name: 'HelpWindows', path: '/Help-Windows', element: lazy(() => import("../pages/User/Tools/HelpWindows")) },
    //#endregion
    //#region خيارات البرنامج 84
    { parentId: 84, perm: 3003, name: 'FrmOption', path: '/Options', element: lazy(() => import("../pages/User/Tools/Options")) },
    //#endregion
    //#region ادارة المستخدمين
    { parentId: 85, perm: 20001, name: 'ManagementUsers', path: '/UsersManag', element: lazy(() => import("../pages/User/Tools/UsersManagement")) },
    { parentId: 85, perm: 20001, name: 'GorpDes', path: '/GroupssManag', element: lazy(() => import("../pages/User/Tools/UsersManagement/GroupsManagement")) },
    { parentId: 85, perm: 20001, path: '/UserPerms', element: lazy(() => import("../pages/User/Tools/UsersManagement/UserPerm")), routeParam: "branch/:id/:isGroup" },
    { parentId: 85, perm: 20001, path: '/GroupPerms', element: lazy(() => import("../pages/User/Tools/UsersManagement/UserPerm")), routeParam: "branch/:id/:isGroup" },
    //#endregion
    //#region ملف التسجيل 86
    { parentId: 86, perm: 3010, name: 'FrmLogFile', path: '/LogFile', element: lazy(() => import("../pages/User/Tools/LogFileNew")) },
    //#endregion
    //#region التدقيق
    { parentId: 87, perm: 3031, name: 'ReviewAudit', path: '/Compliance/Audit', element: lazy(() => import("../pages/User/Tools/Audit")) },
    //#region مراقبة العمليات 87
    { parentId: 87, perm: 3016, name: 'FrmRemExcWarning', path: '/ControlOperations', element: lazy(() => import("../pages/User/Tools/ControlOperations")) },
    //#endregion
    //#endregion
    //#endregion
];

export const agentRouts = [
    ...publicRoutes,
    //#region Agent Dashboard
    { parentId: 0, perm: 0, name: 'AgentDashboard', path: '/AgentDashboard', element: lazy(() => import("../pages/Dashboard/AgentDashboard")) },
    //#endregion
    //#region كشف حساب
    { parentId: 1, perm: 1004, name: 'FrmLedger', path: '/AccountStatement', element: lazy(() => import("../pages/SharedPages/Ledger")) },
    //#endregion
    //#region حوالات
    { parentId: 2, perm: 0, perms: '7003,7803', name: 'SendTransfer', path: '/Remitt/RemRec', element: lazy(() => import("../pages/Agent/Remittance/RemRec")) },
    { parentId: 0, perm: 0, perms: '7003,7803', name: 'SendTransfer', path: '/Remitt/RemittRec', element: lazy(() => import("../pages/Agent/Remittance/RemRecBlack")) },
    { parentId: 2, perm: 0, perms: '7022,7005', name: 'TransferDelivery', path: '/Remitt/RemtPay', element: lazy(() => import("../pages/Agent/Remittance/RemPay")), routeParam: "id", ulrParam: "0" },
    // اخفاءها للعراق مؤقت{ parentId: 2, perm: 7015, name: 'FrmRemittOrders', element: "Remittance/RemOrder" },
    { parentId: 2, perm: 7007, name: 'IncomingFaxes', path: '/Remitt/RemFaxExport', element: lazy(() => import("../pages/Agent/Remittance/SendFax")) },
    { parentId: 2, perm: 7008, name: 'ImportFax', path: '/Remitt/AgentFaxEmport', element: lazy(() => import("../pages/SharedPages/ImportFax")), props: { IsAgent:true } },
    //#endregion
    //#region الاستعلامات
    { parentId: 3, perm: 7207, name: 'RemtsOut', path: '/RemittStatment/OutRemitts', element: lazy(() => import("../pages/Agent/Statments/RemtsOut")) },
    { parentId: 3, perm: 7210, name: 'FrmRemitt_Deliv', path: '/RemittStatment/DelivRem', element: lazy(() => import("../pages/Agent/Statments/DeliveredRem")) },
    { parentId: 3, perm: 7202, name: 'FrmRemitt_NoneDelivery', path: '/RemittStatment/NonDelivRemitts', element: lazy(() => import("../pages/Agent/Statments/NonDelivRem")) },
    { parentId: 3, perm: 7217, name: 'WagesRemt', path: '/AgentStatments/CommInquiry', element: lazy(() => import("../pages/Agent/Statments/CommInquiry")) },
    { parentId: 3, perm: 7201, name: 'UnApprovedtransfers', path: '/RemittStatment/NonApproveRem', element: lazy(() => import("../pages/Agent/Statments/NonApproveRem")) },
    { parentId: 3, perm: 7015, name: 'RequestModify', path: '/RemittStatment/ReqModify', element: lazy(() => import("../pages/Agent/Statments/ReqModify")) },
    { name: 'RequestModify', path: '/RemittStatment/Modify', element: lazy(() => import("../pages/SharedPages/ReqModifyDetails")), routeParam: "remid/:reqid/:reqkind" },
    { parentId: 3, perm: 7229, name: 'FrmFinancingStat', path: '/Adjus/AgentFinancingQuery', element: lazy(() => import("../pages/Agent/Statments/AgentFinancingQuery")) },
    //#endregion
    //#region الاعتمادات
    { parentId: 4, perm: 24, name: 'CreditApproval', path: '/Credit/CreditTransfer', element: lazy(() => import("../pages/Agent/Accounting/Operations/CreditTransfer")) },
    { parentId: 4, perm: 26, name: 'FrmTransferUndeProgress', path: '/Credit/TransferUnderProgress', element: lazy(() => import("../pages/Agent/Accounting/Operations/CreditTransfer")) },
    //#endregion
    //#region معاملات العملاء
    { parentId: 5, perm: 1103, name: 'FrmByAccIn', path: '/Client/AccIn', element: lazy(() => import("../pages/Agent/Accounting/Operations/AccIn")) },
    { parentId: 5, perm: 1104, name: 'FrmByAccOut', path: '/Client/AccOut', element: lazy(() => import("../pages/Agent/Accounting/Operations/AccOut")) },
    { parentId: 5, perm: 1105, name: 'FrmAddClient', path: '/Client/AddClient', element: lazy(() => import("../pages/Agent/Accounting/Operations/AddClient")) },
    //#endregion
    //#region عرض و طلب
    { parentId: 8, perm: 34, name: 'RecourdOrder', path: '/Trade/AddRequest', element: lazy(() => import("../pages/Agent/Accounting/Trading/Requests")) },
    { parentId: 8, perm: 34, name: 'RecordOffer', path: '/Trade/AddOffer', element: lazy(() => import("../pages/Agent/Accounting/Trading/Offers")) },
    { parentId: 8, perm: 34, name: 'RequestsOrdersArchives', path: '/Trade/Archive', element: lazy(() => import("../pages/Agent/Accounting/Trading/Archive")) },
    //#endregion
    //#region نوافذ مساعدة
    { parentId: 7, perm: 0, name: 'FrmUserPassword', path: '/ChangePass', element: lazy(() => import("../pages/Agent/HelpWindows/ChangeAgentPassword")) },
    { parentId: 7, perm: 7512, name: 'OurAgents', path: '/Our-Agents', element: lazy(() => import("../pages/Agent/HelpWindows/OurAgents")) },
    { parentId: 7, perm: 0, name: 'LastLogin', path: '/LastLogin', element: lazy(() => import("../pages/Agent/HelpWindows/LastLogin")) },
    //#endregion
    //#region تبديل عملات
    { parentId: 6, perm: 25, name: 'CurrExchange', path: '/Credit/BetweenCurrencies', element: lazy(() => import("../pages/Agent/Accounting/Operations/BetweenCurrencies")) },

    { parentId: 9, perm: 6028, name: 'FrmAuctionDeliver', path: '/Exch/AuctionDeliver', element: lazy(() => import("../pages/Agent/Accounting/Operations/AuctionDeliver")) },
    //#endregion
    //#region نظام الرسائل
    { perm: 0, name: 'MessageSystem', path: '/AgentMessageSystem', element: lazy(() => import("../pages/SharedPages/MessageSystem")) }
    //#endregion
]

export const clientRouts = [
    ...publicRoutes,
    //Clien Dashboard
    { perm: 0, path: '/ClientDashboard', element: lazy(() => import("../pages/Dashboard/ClientDashboard")) },
]

export const shippingClientRouts = [
    ...publicRoutes,
    // shipping Clien Dashboard
    { perm: 0, path: '/ShippingClientDashboard', element: lazy(() => import("../pages/Dashboard/ShippingClientDashboard")) },

]
