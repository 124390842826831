/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import "./assets/style/util.css";
import ArchiveDialog from "./components/PublicDialogs/ArchiveDialog";
import MsgDialog from "./components/PublicDialogs/MsgDialog";
import ApiLayout from "./layout/ApiLayout";
import AppRoutes from "./routes/index";
import * as app from "./services/AppService";
// import "./services/log.js";
import "primereact/resources/themes/lara-light-cyan/theme.css";
import { reload } from "./store/auth";
import Spinner from "./utils/Spinner";

const ArStyle = React.lazy(() => import("./utils/RtlTheme"));
const EnStyle = React.lazy(() => import("./utils/LtrTheme"));

export default function App() {
  let navigate = useNavigate();
  const store = useSelector((store) => store);
  const path = useLocation().pathname;

  const [state, setState] = useState({
    loading: true,
    OnlyAPI: false,
    options: { winStyle: "bestrate" },
  });

  let IsAr = app.getCookie("language", "en") === "ar";

  useEffect(() => {
    if (path === "/Signin" && [0, 1].includes(store.loginState.userType))
      navigate("/UserDashboard");
    if (path === "/Signin" && +store.loginState.userType === 2)
      navigate("/AgentDashboard");
  }, [path]);

  const dispatch = useDispatch();
  useEffect(() => {
    let IsLogout = ["/OurAgents", "/TrackTransfer", "/"].includes(
      window.location.pathname
    );
    dispatch(
      reload((res) => {
        if (!res.IsLoged && !IsLogout) navigate("/Signin");
        setState({
          loading: false,
          OnlyAPI: res.OnlyAPI,
          options: res.IsLoged ? res.User.Options : res.Options,
        });
      }, IsLogout)
    );
    try {
      if (Notification && Notification.permission !== "granted")
        Notification.requestPermission();
    } catch (e) {}
    if (app.getCookie("colorMode", "empty") === "empty")
      app.setCookie("colorMode", "auto");
    if (app.getCookie("language", "empty") === "empty")
      app.setCookie("language", "en");
  }, []);

  const lang = app.translate("Lang") === "ar" ? "rtl" : "ltr";
  const mode = app.getCookie(
    "colorMode",
    window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light"
  );

  document.body.setAttribute("data-bs-theme", mode);

  return state.loading ? (
    <Spinner Loading={true} />
  ) : state.OnlyAPI ? (
    <ApiLayout />
  ) : (
    <>
      <div
        className={lang}
        style={{ direction: app.translate("Lang") === "ar" ? "rtl" : "ltr" }}
        data-bs-theme={mode}
      >
        <title>{state.options.companyName}</title>
        <React.Suspense fallback={<Spinner Loading />}>
          {IsAr && <ArStyle Style={state.options.winStyle} />}
          {!IsAr && <EnStyle Style={state.options.winStyle} />}
        </React.Suspense>
        <Spinner Loading={store.showSpinner} />
        <MsgDialog {...store.msgDialog} />
        <ArchiveDialog {...store.archiveDialog} />
        <AppRoutes />
      </div>

      {/* <SmallSizeLayout /> */}
    </>
  );
}
