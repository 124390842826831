import React, { useEffect } from "react";
import * as app from "../../services/AppService";
import { useSelector, useDispatch } from "react-redux";
import { getData } from "../../store/data";
import Select, { SelectProps } from "../Select";
import { KeyValue } from "../../models/key-value";

interface Props extends Omit<SelectProps,'Options'> {
    WithAllBranches?: boolean,
    WithOutMainBranch?: boolean,
    OnLoad?:(args:any) => any
}
function UserBranches(props: Props) {
    const dispatch: any = useDispatch();

    useEffect(() => {
        dispatch(getData("UserBranches", {}, (UserBranches: KeyValue[]) => {
            if (UserBranches && UserBranches.length > 0 && props.OnLoad && typeof props.OnLoad == "function")
                props.OnLoad(UserBranches.filter((r: KeyValue) => +r.Key !== 1 || !props.WithOutMainBranch));
        })
        );
    }, []);

    const selector = useSelector((store: any) => store.data.UserBranches);
    const UserBranches = (props.WithAllBranches
        ? [{ Value: app.translate("AllBranchs"), Key: 0 }, ...selector]
        : selector).filter((r: KeyValue) => +r.Key !== 1 || !props.WithOutMainBranch);

    return (
        <Select ID="UserBranches" Label={"Branch"} {...props} Options={UserBranches} />
    );
}

UserBranches.defaultProps = {
  WithAllBranches: false,
};

export default UserBranches;
