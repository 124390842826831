/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import * as app from "../services/AppService"
import Tab  from "./Tab"
interface TabsProps {
    children: Array<React.ReactElement> | React.ReactElement,
    onSelect: (selectedTab: string) => void | undefined,
    Clicked: () => void | undefined,
    onFocus: () => void,
    isVertical: boolean,
    activeTab: string,
    ContainerClass: string,
    Class: string,
    noBody: boolean,
    noShadow:boolean,
    isLocked:boolean,
    isCanceled: boolean,
    style?: object
}
function Tabs(props: TabsProps) {

    const [selectedTab, setSelectedTab] = useState(props.activeTab)
    function selectedChangedHandler(item: any) {
        if (props.onSelect)
            props.onSelect(item.id);
        if (item.Clicked)
            item.Clicked();
        if (item.clicked)
            item.clicked();
        setSelectedTab(item.id)
    }
    var children = (Array.isArray(props.children) ? props.children : [props.children]).filter((r:any) => typeof (r) === "object" && r.hide !== true);

    useEffect(() => {
        if (!props.activeTab && children[0].props.Clicked)
            children[0].props.Clicked()
    }, []);
    useEffect(() => {
        setSelectedTab(props.activeTab)
    }, [props.activeTab])

    return (
        <div className={`${props.isVertical ? 'd-flex align-items-start' : ''} ${props.ContainerClass}`} onFocus={props.onFocus} >
            <ul className={`nav nav-tabs  shadow-sm ${props.isVertical ? 'flex-column' : props.Class}`} id="myTab" role="tablist">
                {children.map((child: any, index: number) =>
                    <li className="nav-item" role="presentation" key={child.props.id}>
                        <button className={`nav-link  ${(selectedTab === child.props.id) || (!selectedTab && index === 0) ? 'active' : ''} ${child.props.icon ? 'iconssc-' + child.props.icon : ''}  ${props.isVertical ? 'rounded-0 border-end-0 border' : ''}`} id="home-tab"
                            type="button" role="tab"
                            aria-controls="home-tab-pane" aria-selected="true"
                            onClick={() => selectedChangedHandler(child.props)} disabled={child.props.disabled}>{app.translate(child.props.label)}</button>
                    </li>
                )}
            </ul>
            {!props.noBody && <div className={`tab-content px-3 pb-2 pt-1 w-100 border border-1 ${props.isVertical ? '' : 'border-top-0'} ${props.noShadow ? 'border-bottom-0' : 'shadow-sm'} overflow-auto position-relative ${(props.activeTab === children[0].props.id && (props.isLocked || props.isCanceled)) ? 'bg-black bg-opacity-10' : 'bg-body-tertiary'}`} style={{ "maxHeight": "calc(100vh - 195px)" }} >
                {children.map((tab: any, index: number) =>
                    <Tab key={index}
                        Class={`tab-pane fade ${(selectedTab === tab.props.id) || (!selectedTab && index === 0) ? ' show active' : 'd-none'}`}>
                        {tab.props.children}
                    </Tab>)
                }
                {selectedTab === children[0].props.id && (props.isLocked || props.isCanceled) &&
                    <div className="position-absolute top-50 start-50 translate-middle  ">
                        <img src={`images/${props.isLocked ? 'lock' : 'cancel'}.png `} className="rounded" alt="..." />
                    </div>
                }
            </div>}
        </div>
    );
}

Tabs.defaultProps = {
    onSelect: undefined,
    Clicked: undefined,
    onFocus: () => { },
    isVertical: false,
    ContainerClass: "",
    Class: "",
    noBody: false,
    noShadow: false,
    isLocked: false,
    isCanceled: false,
}
export default Tabs;
