import React, { ReactElement } from "react";
import store from "../store";
import { hideArchiveDialog, showArchiveDialog } from "../store/archiveDialog";
import { getData } from "../store/data";
import { showMsgDialog } from "../store/msgDialog";
import { hideSpinner, showSpinner } from "../store/spinner";
import * as http from "./http";
import { KeyValue } from "../models/key-value";
import { ResultVM } from "../models/result-vm";
import { CommValueType } from "../models/comm-value-type";
import { PriceRange, PriceResult } from "../models/price-result";

var allCurrencies: KeyValue[] = [];
var identityKind: KeyValue[] = [];
var Jobs: KeyValue[] = [];
var Banks: KeyValue[] = [];
var tradingSocket: any = null;

export function ConnectSocket(refreshData: any, noToken: boolean) {
    const token = store.getState().loginState.token;

    var BaseUrl = window.location.origin.replace("http", "ws");
    var socket = new WebSocket(
        BaseUrl + "/LivePrices?token=" + (noToken ? "" : token)
    );

    socket.onmessage = function (e) {
        var Prices = JSON.parse(e.data).Data || [];
        refreshData((old: any) => ({
            ...old,
            Prices:
                old.Prices.length === 0
                    ? Prices
                    : old.Prices.map((oldPrice: any) => {
                        let newPrice = Prices.find(
                            (r: any) => r.CurrencyCode === oldPrice.CurrencyCode
                        );
                        if (newPrice)
                            return {
                                ...newPrice,
                                OldBuy: oldPrice.Buy,
                                OldSell: oldPrice.Sale,
                            };
                        else return { ...oldPrice, OldBuy: 0, OldSell: 0 };
                    }),
        }));
        //refreshData(arr)
    };

    socket.onopen = function () {
        console.log("socket Opened");
    };

    socket.onerror = function (error) {
        console.log("WebSocket Error " + error);
    };

    return () => {
        if (!socket || socket.readyState !== WebSocket.OPEN)
            console.log("socket not connected");
        else {
            console.log("Closing from client");
            socket.close(1000, "Closing from client");
        }
    };
}
export function ConnectTradingSocket(model: any, refreshData: any) {
    if (tradingSocket && tradingSocket.readyState === WebSocket.OPEN)
        tradingSocket.close(1000, "Trading Closing from client ");

    const token = store.getState().loginState.token;
    var baseUrl = window.location.origin.replace("http", "ws");
    var url = baseUrl + "/Trading?token=" + (token == null ? "" : token);
    if (model.IsComming !== undefined) url += "&IsComming=" + model.IsComming;
    if (model.FromCurrency !== undefined)
        url += "&FromCurrency=" + model.FromCurrency;
    if (model.FromCenter !== undefined) url += "&FromCenter=" + model.FromCenter;
    if (model.ToCenter !== undefined) url += "&ToCenter=" + model.ToCenter;
    if (model.AccountID !== undefined) url += "&AccountID=" + model.AccountID;
    if (model.ParentID !== undefined) url += "&ParentID=" + model.ParentID;

    tradingSocket = new WebSocket(url);

    tradingSocket.onmessage = function (e: any) {
        var data = JSON.parse(e.data).Data || [];
        refreshData(data);
    };

    tradingSocket.onopen = function () {
        console.log("Trading socket Opened");
    };

    tradingSocket.onerror = function (error: any) {
        console.log("Trading WebSocket Error " + error);
    };

    return () => {
        if (!tradingSocket || tradingSocket.readyState !== WebSocket.OPEN)
            console.log("Trading socket not connected");
        else {
            console.log("Trading Closing from client");
            tradingSocket.close(1000, "Trading Closing from client ");
        }
    };
}

export function getAreas(cityID: string, cb: (data: KeyValue[]) => void) {
    if (!cityID || +cityID === 0) return;
    http.get("/Service/GetAreas", { cityID }, (res: ResultVM) => {
        if (cb && typeof cb == "function")
            cb(res.AdditionalData);
    });
}
export function getDistricts(AreaID: string, cb: (data: KeyValue[]) => void) {
    if (!AreaID || +AreaID === 0) return;
    http.get("/Service/GetDistricts", { AreaID }, (res: ResultVM) => {
        if (cb && typeof cb == "function")
            cb(res.AdditionalData);
    });
}
export function getStreets(DistrictID: string, cb: (data: KeyValue[]) => void) {
    if (!DistrictID || !parseInt(DistrictID)) return;
    http.get("/Service/GetStreets", { DistrictID }, (res: ResultVM) => {
        if (cb && typeof cb == "function")
            cb(res.AdditionalData);
    });
}

export function getAccountCurrencies(AccountID: string, cb: (data: KeyValue[]) => void) {
    if (!AccountID) return;
    http.get("/AgentManagement/GetAccountCurrencies", { AccountID }, (res: ResultVM) => {
        if (res.Success)
            cb(res.AdditionalData);
        else
            disblayError(res);
    });
}

export function getDataFromStore(dataName: string, cb: any) {
    store.dispatch(
        getData(dataName, {}, (data: any) => {
            if (cb && typeof cb == "function") cb(data);
        })
    );
}

export function GetKeyValueArr(strinArr: string[], startIndex: number = 0) {
    if (!startIndex) startIndex = 0;
    return strinArr.map((r, index) => ({
        Key: (index + +startIndex).toString(),
        Value: translate(r),
    }));
}
export function ShowLoading() {
    //Opp_Others
    store.dispatch(showSpinner());
}
export function HideLoading() {
    store.dispatch(hideSpinner());
}
export function getPermission(permID: number, permKind?: string) {
    let perms = store.getState().data.perms;
    if (!permKind) permKind = "Opp_Read";
    if (options() && +options().UserType === 1) return true;
    var Perm = perms.find((item: any) => +item.PermName === +permID);
    return Perm ? Perm[permKind] : false;
}
export function options() {
    return store.getState().data.options;
}
export function dataURItoBlob(dataURI: any) {
    var str = dataURI.toString();
    var binary = atob(str.split(",")[1]);
    var mimeString = str.split(",")[0].split(":")[1].split(";")[0];
    var array = [];
    for (var i = 0; i < binary.length; i++) {
        array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], {
        type: mimeString,
    });
}
export function arrayBufferToBase64(buffer: any) {
    if (buffer == null || buffer.length < 2) return "";
    let binary = "";
    let bytes = new Uint8Array(buffer);
    let len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
    }
    return "data:image/png;base64, " + window.btoa(binary);
}
export function print(url: string, body?: any) {
    body = body === undefined ? {} : body;
    http.post(url, body, (res: ResultVM) => {
        if (!res.Success) {
            disblayError(res);
        } else {
            const byteArray = Uint8Array.from(
                atob(res.Message)
                    .split("")
                    .map((char) => char.charCodeAt(0))
            );
            const blobUrl = URL.createObjectURL(
                new Blob([byteArray], { type: "application/pdf" })
            );
            window.open(blobUrl, "_blank");
        }
    });
}

export const toPrint = print;

export function excel(url: string, body: any, fileName: string) {
    body = body === undefined ? {} : body;
    if (!fileName || fileName === "") fileName = "EXCEL";
    http.post(url, body, (res: ResultVM) => {
        if (!res.Success) {
            disblayError(res);
        } else {
            var newPage = document.createElement("a");
            newPage.href =
                "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," +
                res.Message;
            newPage.download = translate(fileName) + ".xlsx";
            newPage.click();
        }
    });
}
export function PreparMessage(Message: string, separator: string = "\n") {
    return Message.split(separator).map((r, index) => (
        <div key={index} >
            {" "}
            {"-" + r} < br />
        </div>
    ));
}
export function showAlert(Body: string | ReactElement | ReactElement[], TitleWithOutTranslate?: string, OnClose?: () => void, Ok?: (strResult: string) => void, CloseLabel?: string, ButtonsArr?: any[]) {
    store.dispatch(showMsgDialog(Body, TitleWithOutTranslate, OnClose, Ok, CloseLabel, ButtonsArr));
}
export function showRespMsg(res: ResultVM, closeCb: () => void) {
    if (res.Success) showAlert("MsgCompleteProcess", "", closeCb);
    else disblayError(res);
}

export function moveConfirm(Body: string | ReactElement[], TitleWithOutTranslate: string, Ok: (strResult: string) => void, OnClose?: () => void, CloseLabel?: string) {
    if (!TitleWithOutTranslate || TitleWithOutTranslate === "") TitleWithOutTranslate = "MsgContinue";
    if (!Body || Body === "") Body = translate("MsgContinue");
    store.dispatch(showMsgDialog(Body, TitleWithOutTranslate, OnClose, Ok, CloseLabel));
}

export function moveReason(ConfirmMsg: string, Label: string, Title: string, Ok?: (strResult: string) => void, OnClose?: () => void, CloseLabel?: string, ExtraButtons?: any[], DefValue?: string) {
    if (!Title || Title === "") Title = "MsgContinue";
    store.dispatch(showMsgDialog("", Title, OnClose, Ok, CloseLabel, ExtraButtons, ConfirmMsg, Label, DefValue));
}
export function showArchive(model: any) {
    store.dispatch(showArchiveDialog(model));
}
export function hideArchive() {
    store.dispatch(hideArchiveDialog());
}

export function translate(key: string | undefined): string {
    let TranslationKeys = store.getState().data.LangKey;
    if (key === undefined || typeof key != "string") return "";
    if (TranslationKeys === undefined) return key;
    for (var i = 0; i < TranslationKeys.length; i++) {
        if (key.toLowerCase() === TranslationKeys[i].Key.toLowerCase())
            return TranslationKeys[i].Value.replace(/&#252;/g, "ü")
                .replace(/&#231;/g, "ç")
                .replace(/&#246;/g, "ö")
                .replace(/&#199;/g, "Ç")
                .replace(/&#220;/g, "Ü")
                .replace(/&#214;/g, "Ö")
                .replace(/&#214;/g, "ş");
        //"i": "İ", "ş": "Ş", "ğ": "Ğ", "ü": "Ü", "ö": "Ö", "ç": "Ç", "ı": "I"
    }
    return key;
}
export function getNumberWriting(number: string | number, currencyID: string | undefined, cb: (numberWriting: string) => void) {
    number = number + "";
    number = number.replace(/,/g, "");
    if (currencyID === undefined || isNaN(+currencyID)) currencyID = "0";
    if (number === undefined || number == null || parseFloat(number) === 0)
        if (cb && typeof cb == "function") cb("");

    http.get("/Service/GetNumberWriting", {
        Number: parseFloat(number),
        CurrencyID: currencyID,
    }, (res: ResultVM) => {
        if (cb && typeof cb == "function") {
            cb(res.AdditionalData);
        }
    }, true);
}
export function disblayError(res: ResultVM, IsWarning?: boolean, cb?: (res: ResultVM) => void) {
    let validationMessage: any = "";
    if (res.ValidationResults && res.ValidationResults.length > 0) {
        validationMessage = (
            <div>
                {
                    res.ValidationResults.map((row) => {
                        return <div>{row.Key + "code #" + row.Value} </div>;
                    })
                }
            </div>
        );
    } else if (res.Message) {
        validationMessage = translate(res.Message) + (res.Code ? " , code " + res.Code : "");
    } else validationMessage = translate("Error_Operation");

    showAlert(validationMessage, IsWarning ? "Warning" : "Validation", () => {
        if (cb && typeof cb == "function") cb(res);
    });
}
export function disblayErrorOrWarnings(res: ResultVM, model: any, save: () => void, errorCb?: (res: ResultVM) => void) {
    if (
        res.ValidationResults.length > 0 ||
        !res.CarryOnData ||
        res.CarryOnData.length === 0 ||
        res.CarryOnData.filter((r: any) => !r.Approv).length === 0
    )
        disblayError(res, false, errorCb);
    else {
        var Msg = "";
        for (var i = 0; i < res.CarryOnData.length; i++) {
            if (!res.CarryOnData[i].Approv) {
                Msg += res.CarryOnData[i].Msg + " ";
            }
        }
        showAlert(
            Msg,
            "Warning",
            function () { },
            function () {
                model.WarningList = [];
                for (var i = 0; i < res.CarryOnData.length; i++) {
                    model.WarningList.push({
                        WarningExeption: res.CarryOnData[i].WarningExeption,
                        Approv: true,
                    });
                }
                save();
            }
        );
    }
}
export function roundNumber<T extends string | number = number>(number: number | string, Currency: number | string = "0", returnString = false): T {
    try {
        number = +(number + "").replace(/,/g, "");
        if (isNaN(number)) return 0 as T;
        var NumbersAfterDecimal = options().NumbersAfterDecimal;
        var RoundType = options().RoundType;
        var RoundTo = 2;
        var CurrList = options().CurrList;
        if (+Currency !== 0) {
            for (var i = 0; i < CurrList.length; i++) {
                if (+CurrList[i].Key === +Currency) {
                    RoundTo = +CurrList[i].Value === 0 ? 2 : +CurrList[i].Value;
                    break;
                }
            }
        }
        var value = (+number).toLocaleString("en-US", {
            useGrouping: false,
            maximumFractionDigits: RoundTo,
        });
        value = (+value).toLocaleString("en-US", {
            useGrouping: false,
            minimumFractionDigits: NumbersAfterDecimal,
        });
        var last = value.substr(value.length - 1);
        if (
            RoundTo > 0 &&
            RoundType > 0 &&
            NumbersAfterDecimal > 0 &&
            +value % 1 > 0
        ) {
            value = value + "";
            switch (RoundType) {
                case 1:
                    switch (last) {
                        case "1":
                        case "2":
                            value = value.substr(0, value.length - 1) + "0";
                            break;
                        case "3":
                        case "4":
                        case "6":
                        case "7":
                            value = value.substr(0, value.length - 1) + "5";
                            break;
                        case "8":
                            value = ((+value * Math.pow(10, RoundTo) + 2) / Math.pow(10, RoundTo)).toString();
                            break;
                        case "9":
                            value = ((+value * Math.pow(10, RoundTo) + 1) / Math.pow(10, RoundTo)).toString();
                            break;
                        default:
                            break;
                    }
                    break;
                case 2:
                    value = (+last <= 5 ? (value + "").substr(0, (value + "").length - 1) + "0"
                        : (+value * Math.pow(10, RoundTo) + (10 - +last)) / Math.pow(10, RoundTo)).toString();
                    break;
                case 3:
                    var last25 = +(value.substr(value.length - 2)) % 25;
                    value = (last25 <= 12 ?
                        (+value * Math.pow(10, RoundTo) - last25) / Math.pow(10, RoundTo)
                        : (+value * Math.pow(10, RoundTo) + (25 - +last25)) / Math.pow(10, RoundTo)).toString();
                    break;
                case 4:
                    var last50 = +(value.substr(value.length - 2)) % 50;
                    value = (last50 <= 25
                        ? (+value * Math.pow(10, RoundTo) - last50) / Math.pow(10, RoundTo)
                        : (+value * Math.pow(10, RoundTo) + (50 - +last50)) / Math.pow(10, RoundTo)).toString();
                    break;
                default:
                    break;
            }
        }
        value = (+value).toLocaleString("en-US", {
            useGrouping: returnString,
            minimumFractionDigits: NumbersAfterDecimal,
        });
        if (+number !== 0 && +value === 0) return number as T;
        return value as T;
    } catch (e) {
        return number as T;
    }
}
export function getAccounts(cb: (accounts: KeyValue[]) => void, CurrencyID = 0, AccountsKind = "", Level = 5) {
    if (+CurrencyID === 0 && +Level === 5) {
        cb([]);
        return;
    }
    http.get("/Operation/GetAccounts", {
        CurrencyID, AccountsKind, Level
    }, (res: ResultVM) => {
        if (cb && typeof cb == "function") cb(res.AdditionalData);
    });
}
export function getAccountBalance(accountID: string | number, cb: (balance: string) => void) {
    if (+accountID === 0) {
        cb("0");
        return;
    }
    http.get("/Service/GetAccountBalance", {
        AccountID: accountID
    }, (res: ResultVM) => {
        if (cb && typeof cb == "function")
            cb(res.AdditionalData);
    }
    );
}
export function getUserTellers(User_ID: string, cb: (tellers: KeyValue[]) => void) {
    if (!User_ID) {
        cb([]);
        return;
    }
    http.get("/Service/GetUserTellers", {
        User_ID
    }, (res: ResultVM) => {
        if (cb && typeof cb == "function") cb(res.AdditionalData);
    });
}

export function GetCardRequirement(cb: (cardReq: any) => void) {
    http.get("/Service/GetCardRequirement", {}, (res: ResultVM) => {
        var CardRequirement = {
            //obg: { FieldName: "", Required: false, Show: false }
        };
        res.AdditionalData.forEach((r: { FieldName: string, Required: boolean, Show: boolean }) => {
            //@ts-ignore
            CardRequirement[r.FieldName] = { Required: r.Required, Show: r.Show };
        });
        cb(CardRequirement);
    });
}

export function haveBulletinToday(MoveDate: string, cb: (res: ResultVM) => void) {
    http.get("/Service/NoBulletinToday", {
        MoveDate: MoveDate
    }, (res: ResultVM) => {
        if (!res.AdditionalData)
            cb(res);
        else
            moveConfirm(translate("MsgNoCurrThisDate"), "", function () {
                if (cb && typeof cb == "function") {
                    cb(res);
                }
            });
    });
}

export function getTellerCurrenciesList(cb: (curr: KeyValue[]) => void, tellerId: string) {
    if (+tellerId === 0) {
        cb([]);
        return;
    }
    http.get("/Operation/GetTellerCurrenciesList", { tellerId }, (res: ResultVM) => {
        if (cb && typeof cb == "function")
            cb(res.AdditionalData);
    });
}

export function getTellerAccounts(tellerId: string, currencyId: string, BoxesKind: number | string, cb: (boxes: KeyValue[]) => void) {
    http.get("/Operation/GetBoxes", { tellerId, currencyId, BoxesKind }, (res: ResultVM) => {
        if (cb && typeof cb == "function")
            cb(res.AdditionalData);
    });
}
export function getBankAccounts(branchID: string | number, currencyId: string | number, cb: (accounts: KeyValue[]) => void) {
    if (+currencyId === 0) return;
    if (+currencyId === -1) currencyId = "0";
    http.get("/Operation/GetBankAccounts", {
        branchID,
        currencyId
    }, (res: ResultVM) => {
        if (cb && typeof cb == "function") cb(res.AdditionalData);
    });
}
export function GetCommTypeValue(slide: any, cb: (res: CommValueType) => void, url?: string) {
    if (+slide.Currency_ID === 0 || +slide.Amount === 0) {
        cb(new CommValueType());
        return;
    }
    http.post(url ? url : "/Service/GetCommTypeValue", slide, (res: ResultVM) => {
        if (cb && typeof cb == "function") cb(res.AdditionalData);
    });
}

export function getAllCurrencies(cb: (curr: KeyValue[]) => void) {
    if (allCurrencies.length === 0) {
        http.get("/Service/GetAllCurrencies", {}, (res: ResultVM) => {
            allCurrencies = res.AdditionalData;
            if (cb && typeof cb == "function") cb(res.AdditionalData);
        });
    } else if (cb && typeof cb == "function")
        cb(allCurrencies);
}

export function getBanks(cb: (banks: KeyValue[]) => void) {
    if (Banks.length === 0) {
        http.get("/Service/GetBanks", {}, (res: ResultVM) => {
            Banks = res.AdditionalData;
            if (cb && typeof cb == "function") cb(res.AdditionalData);
        });
    } else if (cb && typeof cb == "function")
        cb(Banks);
}

export function getIdentityKind(cb: (kinds: KeyValue[]) => void) {
    if (identityKind.length === 0) {
        http.get("/Service/GetIdentityKind", {}, (res: ResultVM) => {
            identityKind = res.AdditionalData;
            if (cb && typeof cb == "function") cb(res.AdditionalData);
        }
        );
    } else if (cb && typeof cb == "function")
        cb(identityKind);
}

export function getJobs(cb: (jobs: KeyValue[]) => void) {
    if (Jobs.length === 0) {
        http.get("/Service/GetJobs", {}, (res: ResultVM) => {
            Jobs = res.AdditionalData;
            if (cb && typeof cb == "function")
                cb(res.AdditionalData);
        }
        );
    } else if (cb && typeof cb == "function")
        cb(Jobs);
}

export function setCookie(name: string, value: string, days: number) {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
}

export function getCookie(name: string, defValue: string) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(";");
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === " ") c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return defValue;
}

export function eraseCookie(name: string) {
    document.cookie = name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
}

export function dateToString(date: Date) {
    return (
        date.getFullYear() +
        "-" +
        (date.getMonth() < 9 ? "0" : "") +
        (date.getMonth() + 1) +
        "-" +
        (date.getDate() < 10 ? "0" : "") +
        date.getDate()
    );
}

export function today(addDays: number = 0) {
    let date = new Date(new Date().getTime() + addDays * 24 * 60 * 60 * 1000);
    return dateToString(date);
}

export function now(number: number) {
    if (!number) number = 0;
    let date = new Date(new Date().getTime() + number * 60 * 60 * 1000);
    return (
        (date.getHours() < 10 ? "0" : "") +
        date.getHours() +
        ":" +
        (date.getMinutes() < 9 ? "0" : "") +
        (date.getMinutes() + 1)
    );
}

export function getBranchTellers(branchId: string, cb: (tellers: KeyValue[]) => void) {
    http.get("/Service/GetBranchTellers", { branchId }, (res: ResultVM) => {
        if (cb && typeof cb == "function")
            cb(res.AdditionalData);
    });
}
export function getAllBranchTeller(branchId: string, cb: (tellers: KeyValue[]) => void) {
    http.get("/Service/GetAllBranchTeller", { branchId }, (res: ResultVM) => {
        if (cb && typeof cb == "function")
            cb(res.AdditionalData);
    });
}
export function findObjectByKey(array: any[], key: string, value: string) {
    if (array && array != null)
        for (var i = 0; i < array.length; i++) {
            if (array[i][key] + "" === value + "") {
                return array[i];
            }
        }
    return null;
}

export function SaveFrozenReason(accountID: string, reason: string, cb: (accounts: ResultVM) => void) {
    http.get("/AccManagement/BlockAccount", {
        AccountIDL4: accountID,
        BlockReason: reason
    }, (res: ResultVM) => {
        if (cb && typeof cb == "function") cb(res);
    });
}

export function OpenOrigin(Kind: number | string, ID: string | number) {
    if (+Kind === 0 || +ID === 0) return;
    var baseUrl = http.BaseUrl();
    switch (+Kind) {
        case 1: //قيد محاسبي
            window.open("/AccOperations/AccountEntry/" + ID);
            break;
        case 5: //تسوية
            window.open("/AccOperations/Adjustment/" + ID);
            break;
        case 12: //تغطية
            window.open("/AccOperations/CoverBalance/" + ID);
            break;
        case 24: //اعتماد رصيد
            window.open(baseUrl + "/AgentOperations/CreditTransfer/" + ID);
            break;
        case 25: //تبديل عملات
            window.open(baseUrl + "/AgentOperations/BetweenCurrencies/" + ID);
            break;
        case 26: //اعتمادات قيد التنفيذ
            window.open(baseUrl + "/AccOperations/TransferInProgressUser/" + ID);
            break;

        case 3: //مقبوضات
            window.open(baseUrl + "/AccOperations/CashOperations/Receive/" + ID);
            break;
        case 4: //مدفوعات
            window.open(baseUrl + "/AccOperations/CashOperations/Payment/" + ID);
            break;
        case 10: //ايداع في الحساب
            window.open(baseUrl + "/AccOperations/CashOperations/Deposit/" + ID);
            break;
        case 11: //سحب من الحساب
            window.open(baseUrl + "/AccOperations/CashOperations/Withdraw/" + ID);
            break;
        case 13: //امر صرف
            window.open(baseUrl + "/AccOperations/CashOperations/CashOrder/" + ID);
            break;

        case 14: //مقبوضات
            window.open(baseUrl + "/Accounting/Cards/CardBuy/" + ID);
            break;
        case 15: //مدفوعات
            window.open(baseUrl + "/Accounting/Cards/CardSell/" + ID);
            break;
        case 16: //ايداع في الحساب
            window.open(baseUrl + "/Accounting/Cards/RechargeCards/" + ID);
            break;
        case 20: //امر صرف
            window.open(baseUrl + "/Accounting/Cards/CardWithdraw/" + ID);
            break;

        case 66:
            window.open(baseUrl + "/AccOperations/CashOperations/AuctionPay/" + ID);
            break;
        case 30: //امر صرف
            window.open(baseUrl + "/ImportFinance/SellingFinancing/" + ID);
            break;
        case 32:
            window.open(baseUrl + "/Exch/ExchBuy/" + ID);
            break;
        case 33:
            window.open(baseUrl + "/Exch/ExchSale/" + ID);
            break;
        case 37:
        case 38:
            window.open(baseUrl + "/Exchange/MetalOperations/" + Kind + "/" + ID);
            break;
        case 51:
            window.open(baseUrl + "/RemOperations/RemittRec/" + ID);
            break;
        case 52:
            window.open(baseUrl + "/RemOperations/RemittIn/" + ID);
            break;
        case 55:
        case 56:
        case 58:
        case 59:
        case 60:
            http.get(
                "/Remittance/IsInstant",
                {
                    ID: ID,
                    Kind: Kind,
                },
                (res: ResultVM) => {
                    if (res.Code === "58")
                        window.open(baseUrl + "/RemOperations/RecInstant/" + res.Message);
                    else if (res.Code === "59")
                        window.open(baseUrl + "/RemOperations/InInstant/" + res.Message);
                    else if (res.Code === "60")
                        window.open(baseUrl + "/RemOperations/InOutInstant/" + res.Message);
                    else if (res.Code === "52")
                        window.open(baseUrl + "/RemOperations/RemittIn/" + ID);
                    else if (res.Code === "51")
                        window.open(baseUrl + "/RemOperations/RemittRec/" + ID);
                    else if (res.Code === "55")
                        window.open(baseUrl + "/RemOperations/RemittPay/" + ID);
                    else if (res.Code === "56")
                        window.open(baseUrl + "/RemOperations/RemittOut/" + ID);
                }
            );
            break;
        case 99:
            window.open(baseUrl + "/ExTrOperation/MatInvoice/0/" + ID);
            break;
        case 57:
            window.open(baseUrl + "/RemOperations/RemByAcc/" + ID);
            break;
        case 65:
            window.open(baseUrl + "/Exch/AuctionSale/" + ID);
            break;
        case 67:
            window.open(baseUrl + "/Exch/AuctionDeliver/" + ID);
            break;
        default:
    }
}

export function GetPriceRange(Cur1: string, Cur2: string, type: number, Kind: number, AccID: string, cb: (priceRange: PriceRange) => void) {
    if (AccID === undefined) AccID = "0";
    if (+Cur1 === +Cur2) {
        if (cb && typeof cb == "function")
            cb({ Min_Value: 1, Max_Value: 1, Range_Available: "" });
        return;
    }
    http.get("/Service/GetPriceRange", {
        Cur1,
        Cur2,
        type,
        Kind,
        AccID
    }, (res: ResultVM) => {
        if (cb && typeof cb == "function")
            cb(res.AdditionalData);
    });
}

export function GetPrice(currency1Id: string, currency2Id: string, way: number, PriceKind: number,
    cb: (price: PriceResult) => void, AgentAccCurrency = "0", Amount = 0, PriceDate: string | null = null) {
    if (+currency1Id === +currency2Id) {
        if (cb && typeof cb == "function") {
            cb({ price: 1, type: 0 });
        }
        return;
    }
    var obj :any = {
        currency1Id,
        currency2Id,
        type: way,
        PriceKind,
        AgentAccCurrency,
        Amount
    };
    if (PriceDate != null) obj["PriceDate"] = PriceDate;
    http.get("/Service/GetCurrencyPrice", obj, (res: ResultVM<PriceResult>) => {
        if (cb && typeof cb == "function")
            cb(res.AdditionalData);
    });
}

export function GetAccountPrice(accountId: string, cur1: string, cur2: string, way: number, kind: number, cb: (price: PriceResult) => void, Amount = 0, AgentID = 0) {
    if (+cur1 === +cur2 || +cur1 === 0 || +cur2 === 0) {
        if (cb && typeof cb == "function")
            cb({ price: 1, type: 0 });
        return;
    }
    http.get("/Service/GetAccountCurrencyPrice", {
        AccountId: accountId,
        currency1Id: cur1,
        currency2Id: cur2,
        type: way,
        PriceKind: kind,
        Amount: Amount ? Amount : 0,
        AgentID: AgentID ? AgentID : 0,
    }, (res: ResultVM) => {
        if (cb && typeof cb == "function")
            cb(res.AdditionalData);
    });
}

export function RefreshAgentBalance() {
    store.dispatch(getData("AgentBalance"));
}

export function getBoolean(val: any) {
    return String(val).toLocaleLowerCase() === "true";
}
