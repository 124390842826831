/* eslint-disable react-hooks/exhaustive-deps */
import { AutoComplete as Auto, AutoComplete } from "primereact/autocomplete";
import React, { useEffect, useRef, useState } from "react";
import { KeyValue } from "src/models/key-value";
import { RowItem } from "src/models/row-item";
import * as app from "../services/AppService";
import "./Autocomplete.css";

interface Props extends RowItem {
  Model: number | string | KeyValue;
  Options: { Key: string | number; Value: String }[];
  Label?: string;
  LabelClass?: string;
  Class?: string;
  Disabled?: boolean;
  Required?: boolean;
  Placeholder?: string;
  Icon?: string;
  DefaultValue?: string | number;
  containerClass?: string;
  Min?: string | number;
  ID?: string | number;
  IsTable?: Boolean;
  LabelElm?: any;
  Key?: string;
  disabled?: boolean;
  OnBlur?: (...args: any) => any;
  OnEnterClicked?: (...args: any) => any;
  OnChange?: (
    Key: string,
    item?: KeyValue | undefined,
    AutoKey?: string,
    Options?: KeyValue[]
  ) => void;
  OnSearch?: (...args: any) => any;
  OnFocus?: (...args: any) => any;
  // ?Filter: (...args: any) => any;
  IconClicked?: () => any;
  showNewWord?: boolean;
}

export default function Autocomplete(props: Props) {
  const [options, setOptions] = useState<{ Key: any; Value: any }[]>(
    props.Options || []
  );
  const [value, setValue] = useState<string | number | KeyValue>(
    props.DefaultValue ||
      (!props.Model || String(props.Model) === "0" ? "" : props.Model!)
  );
  // get object value from selected mode id and set value to object

  // check if they update trigger by search
  const [searchTrigger, setSearchTrigger] = useState(false);

  const ref = useRef<AutoComplete>(null);

  useEffect(() => {
    setOptions(props.Options!);
  }, [props.Options]);

  useEffect(() => {
    // if (props.ID === "sender") {
    //   console.log(
    //     `model change 🔥 ${props.ID}`,
    //     props.Model,
    //     " => ",
    //     props.Options?.length
    //   );
    // }

    if (
      props.Model &&
      typeof props.Model === "number" &&
      +props.Model !== 0 &&
      props.Options?.length
    ) {
      const selectedItem = props.Options.find(
        (item) => +item.Key === +(props.Model || 0)
      );

      if (selectedItem) {
        setValue(selectedItem as any);
      }
    } else if (typeof props.Model === "string" && props.Options?.length) {
      const selectedItem = props.Options.find(
        (item) => item.Key === props.Model
      );

      if (selectedItem) {
        setValue(selectedItem as any);
      }
    } else if (typeof props.Model === "object" && props.Options?.length) {
      const m = props.Model as KeyValue;

      const selectedItem = props.Options.find((item) => +item.Key === +m.Key);

      if (selectedItem) {
        setValue(props.Model);
      }
    } else if (props.DefaultValue) {
      const selectedValue: KeyValue = {
        Key:
          typeof props.Model === "object"
            ? props.Model.Value
            : (props.Model as any),
        Value: String(props.DefaultValue),
      };
      if (selectedValue) {
        setValue(selectedValue);
      }
    }

    if (+props.Model === 0) {
      // when they model reset to 0 remove they selected  value
      const selectedValue = (props.Options || []).find(
        (k) => String(k.Key) === "0"
      );

      if (selectedValue) {
        setValue(selectedValue as any);
      } else {
        setValue("");
      }
    }
  }, [props.Model, props.Options]);

  useEffect(() => {
    // if they option updated throw seach by enter key they show they new list
    // enter value -> enter clikced -> new option  -> show result
    if (options!.length && searchTrigger) {
      openOption();
    }

    setSearchTrigger(false);
  }, [options]);

  function onInputFocus(event: any) {
    openOption();
    if (props.OnFocus && typeof props.OnFocus == "function") props.OnFocus();
  }

  function hanldeKeydown(evt: any) {
    if (evt.charCode === 13) {
      evt.preventDefault();
      sendSearchRequest();
    } else {
    }
  }

  function handleKeydown(evt: any) {
    const text = (ref.current?.getInput() as any)?.value || "";

    if (evt.keyCode === 8) {
      setValue(text);
    }
    // after search and clear they text show they list if there is an options
    if (evt.keyCode === 8 && text.length === 0 && options.length > 0) {
      resetOption();
      openOption();
    }
  }

  function sendSearchRequest() {
    if (props.OnEnterClicked && typeof props.OnEnterClicked == "function") {
      if (typeof value === "string") {
        props.OnEnterClicked(value);
      } else if (typeof value === "object") {
        props.OnEnterClicked(value.Value);
      }
      setSearchTrigger(true);
    }
  }

  // open and show the list at click
  function openOption() {
    if (ref.current) {
      if (options?.length) {
        // search();
        ref.current.show();
      }
    }
  }

  function search({ query = "" }: any) {
    if (props.OnSearch) {
      setOptions([]);
      const result = props.OnSearch(query);

      if (result instanceof Promise) {
        result.then((result: any[]) => {
          if (result.length) {
            setSearchTrigger(true);
          }
        });
      }

      setSearchTrigger(true);
    } else if (query && props.Options) {
      // if (typeof value === "object") {
      //   setOptions([
      //     //@ts-ignore
      //     ...props.Options?.filter((i: KeyValue) => i.Value === value.Value),
      //   ]);
      // } else {
      //   setOptions([
      //     ...props.Options?.filter((i: any) =>
      //       String(i.Value).includes(String(value))
      //     ),
      //   ]);
      // }

      setOptions([
        ...props.Options?.filter((i: any) =>
          String(i.Value).toLowerCase().includes(query.toLowerCase())
        ),
      ]);
    } else {
      resetOption();
    }
  }

  function onblur() {
    if (props.OnBlur) {
      if (typeof value === "object") {
        props.OnBlur?.(value);
      } else if (value) {
        props.OnBlur?.({ Value: value });
      } else {
      }
    } else {
      setTimeout(() => {
        //@ts-ignore
        if (document.activeElement !== ref.current?.getInput()) {
          if (typeof value === "string") {
            if (props.OnChange) {
              props.OnChange("0", {} as any);
            }
            setValue("");
          }
        }
      }, 250);
    }
  }

  function resetOption() {
    setOptions([...props.Options!]);
  }

  function ItemTemplate(item: KeyValue) {
    return (
      <div
        className={
          "flex align-items-center" +
          (+item.Key === 0 && props.showNewWord ? " bold" : "")
        }
      >
        <div>
          {" "}
          {+item.Key === 0 && props.showNewWord
            ? `( ${item.Value} )${app.translate("new")}`
            : item.Value}{" "}
        </div>
      </div>
    );
  }

  return (
    <div className={`auto-control  mt-1 ${props.containerClass || "mt-1"}`}>
      {props.Label && !props.IsTable && (
        <div className="d-flex justify-content-between ">
          <label
            className={`fw-semibold ${
              props.LabelClass || "text-primary-emphasis"
            }`}
          >
            {app.translate(props.Label)}
            {/* {props.Model} - {props.Options?.length} */}
          </label>
          {props.LabelElm !== undefined && (
            <label className="fw-semibold text-danger me-2">
              {props.LabelElm}
            </label>
          )}
        </div>
      )}
      {/* {JSON.stringify(value)} */}
      <div className=" ctr border rounded bg-light-subtle">
        <div className=" w-100 input-group d-flex  flex-nowrap">
          <Auto
            ref={ref}
            field="Value"
            delay={500}
            minLength={+(props.Min || 0)}
            key={props.Key}
            inputId={props.ID ? String(props.ID) : "auto" + props.Label}
            disabled={props.Disabled}
            onKeyPress={hanldeKeydown}
            onKeyDown={handleKeydown}
            onKeyUp={handleKeydown}
            onFocus={onInputFocus}
            onClick={openOption}
            value={value}
            completeMethod={search}
            suggestions={options}
            onChange={(e) => {
              e.originalEvent?.preventDefault();
              setValue(e.value);
            }}
            onSelect={(e) => {
              if (typeof e.value === "object") {
                props?.OnChange?.(
                  e.value?.Key,
                  e.value,
                  "",
                  props.Options as any
                );
              }
            }}
            onBlur={() => onblur()}
            className={`  border-0 rounded ${
              props.Required
                ? " is-invalid  border-1"
                : " border border-0 " + props.Class
            }`}
            required={props.Required}
            itemTemplate={ItemTemplate}
          ></Auto>
          {props.OnEnterClicked && (
            <button
              className="btn btn-primary border-0 p-0 px-2 mobile-mode"
              type="button"
              onClick={() => {
                sendSearchRequest();
              }}
            >
              <i className="icofont-ui-search"></i>
            </button>
          )}

          {props.Icon && (
            <button
              className="btn btn-primary border-0 p-0 px-2"
              type="button"
              disabled={!props.IconClicked}
              onClick={() => {
                props.IconClicked?.();
              }}
            >
              <i className={` iconssc-${props.Icon}`} />
            </button>
          )}
        </div>
      </div>
    </div>
  );
}
