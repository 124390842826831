import React from 'react';
interface TabProps { 
    Class: string;
    id: string;
    label: string;
    tabIndex: number;
    onFocus: ()=>void;
    children: React.ReactElement[] | React.ReactElement;
    icon?: string
    disabled?: boolean
}

 function Tab(props: TabProps) {
    return (        
        <div className={props.Class} id={props.id} role="tabpanel" tabIndex={props.tabIndex} onFocus={props.onFocus}>
            {props.children }
        </div>            
    );
}

Tab.defaultProps = {
    Class: "",
    id: "",
    label: "",
    tabIndex: 0,
    onFocus: () => {}, 
}

export default Tab
