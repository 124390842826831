//@ts-nocheck
import { Modal } from "react-bootstrap";
import Button from "../Button";
import * as app from "../../services/AppService"
import React, {  ReactElement } from "react"

export interface DialogProps {
   OnClose: () => void;
   Ok: () => void;
   Title: string;
   Show: boolean;
   CloseLabel: string;
   OkLabel: string;
   Size: "sm" | "md"| "lg" | "xl";
   onFocus: () => any;
    children: ReactElement | ReactElement[];
   OtherButtons?:any[]
 }

export default function Dialog(props: Partial<DialogProps>) {


    function Close() {
        if (props.OnClose && typeof (props.OnClose) == "function")
            props.OnClose();
    }

    function Ok() {
        if (props.Ok && typeof (props.Ok) == "function")
            props.Ok()
    }

    function Clicked(clicked:any) {
        if (clicked && typeof (clicked) == "function")
            clicked()
    }

    return (
        <Modal data-bs-theme={app.getCookie("colorMode", window.matchMedia('(prefers-color-scheme: dark)').matches ? "dark" : "light")} backdrop="static"
        show={props.Show} size={`${props.Size || 'sm'}`} onHide={Close} style={{ direction: app.translate("Lang") === "ar" ? "rtl" : "ltr" }}>
            <Modal.Header onClick={Close} className="p-2 border-bottom text-primary-emphasis bg-body-tertiary">
                <Modal.Title className="w-100">{app.translate(props.Title)}</Modal.Title>
                {props.OnClose && <Button Class="btn btn-danger" Clicked={Close} Icon="close-1" />}
            </Modal.Header>
            <Modal.Body className="p-2 text-start bg-body-tertiary " onFocus={props.onFocus} >{props.children}</Modal.Body>
            <Modal.Footer className="p-1 bg-body-tertiary border-top">
                <div className=" me-auto">
                    {props.OtherButtons && props.OtherButtons.map((item, i) => { return <Button key={i} Class={"btn-primary shadow-sm mx-1 " + item.Class} Clicked={() => Clicked(item.Clicked)} Label={item.Label} Icon={item.Icon} /> })}
                </div>
                {props.OnClose && <Button Class="btn btn-danger" Clicked={Close} Label={props.CloseLabel} Icon="close-1" />}
                {props.Ok && <Button Class="btn btn-success" Clicked={Ok} Label={props.OkLabel} Icon="done-1" />}
            </Modal.Footer>
        </Modal>
    );
}



Dialog.defaultProps = {
    Title: "",
    Show: true,
    CloseLabel: 'Close',
    OkLabel: 'Ok',

}

