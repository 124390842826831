import React, { ChangeEvent } from "react";
import * as app from "../services/AppService";
import { RowItem } from "../models/row-item";
interface TimePickerProps extends RowItem{
    OnChange: (text: string,) => void,
    OnBlur: () => void,  
    ID: string,
    Label: string,  
    IsTable: boolean,
    TableClass: string,
    Disabled: boolean,
    Model: string,
    Placeholder: string,    
    Class: string,
    Required: boolean,
}
function TimePicker(props: TimePickerProps) {

    function handler(event: ChangeEvent<HTMLInputElement>) {
        const { value } = event.target;
        if (props.OnChange && typeof props.OnChange == "function")
            props.OnChange(value);
    }

    function blurhandler(event: ChangeEvent<HTMLInputElement>) {
        const { value } = event.target;
        if (value == '')
            props.OnChange(app.today());

    }
    var datePicker = <input
        id={props.ID ? props.ID : "date" + props.Label}
        type="time"
        value={props.Model}
        className={`form-control form-control-sm rounded ${props.Required && !props.Model ? " is-invalid  border-1" : props.IsTable ? " border-1 text-center bg-body-tertiary " + props.TableClass : "border-0 " + props.Class}`}
        disabled={props.Disabled}
        onChange={handler}
        onBlur={blurhandler}
        placeholder={app.translate(props.Placeholder)}
        max="3000-12-31"
        required={props.Required}
    />;

    return (
        props.IsTable ? datePicker :
            <div className="mt-1">
                <label className="fw-semibold text-primary-emphasis">{app.translate(props.Label)}</label>
                <div className="input-group border rounded">
                    {datePicker}
                </div>
            </div>
    );
}

TimePicker.defaultProps = {
    Label: "",
    Model: new Date(),
    Placeholder: "",
    Class: " ",
    Disabled: false,
    Required: false,
    IsTable: false
}

export default TimePicker;